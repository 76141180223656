import React from "react";
import { Select } from "mui-rff";
import { Grid, MenuItem, Typography } from "@material-ui/core";

const NAMES_TO_HIDE_EMPTY_OPTION = [
  "PROCESS_USER",
  "NACIONALIDAD",
  "PAIS",
  "GERENCIA",
  "DEPARTAMENTO",
  "SECCION",
  "SEXO",
  "ESTADO CIVIL",
  "CONTRATISTA",
  "ESQVAC",
  "CON_BENEF",
  "PERM_BENEF",
  "CTO_BENEF",
  "WEIGHT_DISTRIBUTION_FOR_NON_EXISTENT",
]

const Options = ({ label, onChange, opcionesSelect, name, useWhiteText, required, disableInput, useMobileSize, audioPlayerIcon }) => {
  return (
    <Grid container item xs={12} alignItems="center" style={{ height: 64 }}>
      <Grid container direction="row" alignItems="center" wrap="nowrap" item xs={useMobileSize ? 12 : 6}>
        <Typography variant={"subtitle1"} className={"field"}>
          <span id={name} className={"editable-field-label" + useWhiteText && "whiteText"}>{label}:</span>
        </Typography>
        {
          audioPlayerIcon
        }
        {
          required &&
          <Typography variant={"subtitle1"} className={"errorColor field"} style={{ paddingLeft: 5 }}>
            {"*"}
          </Typography>
        }
      </Grid>
      <Grid item xs={useMobileSize ? 12 : 6}>
        <div>
          <Select
            disabled={disableInput}
            label={" "}
            name={name}
            onChange={e => onChange(e.target.value)}
            className={"custom-input-full-width-my-data"}
            formControlProps={{
              margin: "none",
              padding: 0,

            }}>
            {
              !NAMES_TO_HIDE_EMPTY_OPTION.includes(name) &&
              <MenuItem className={"whiteText"} value={""}>
                {""}
              </MenuItem>
            }
            {
              opcionesSelect?.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                );
              })
            }
          </Select>
        </div>
      </Grid>
    </Grid>
  );
};

export default Options;
