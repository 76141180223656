import * as actionTypes from "./actionTypes";
import {
  getGoalsAndObjectivesAPI,
  deleteGoalsAndObjectivesAPI,
  createGoalsAndObjectivesAPI,
  modifyGoalsAndObjectivesAPI,
  verifyGoalsAndObjectivesAPI,
  getGoalsAndObjectivesTasksAPI,
  getGoalsAndObjectivesOneAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "INVALID_DATES":
      errorString = "Las fechas ingresadas no son válidas";
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe un objetivo con el mismo nombre o código';
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar el proceso especial porque está en uso';
      break;
    case "WEIGHTING_EXCEEDED":
      errorString = 'La suma de los ponderados del colaborador en el período seleccionado no puede superar el valor máximo de 100';
      break;
    case "IS_EVALUATED":
      errorString = `No es posible eliminar el objetivo porque ya ha sido calificado en la evaluación: ${e.response.data?.evaluationName}`;
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getGoalsAndObjectivesAction = (period) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getGoalsAndObjectivesAPI((period && period !== "-") ? `?period=${period}` : '');

    let data = response.data && response.data.data;
    let collaboratorList = response.data && response.data.collaboratorList;
    let enabledCollaboratorActions = response.data && response.data.enabledCollaboratorActions;
    let periodList = response.data && response.data.periodList;
    let statusList = response.data && response.data.statusList;
    let targetTypeList = response.data && response.data.targetTypeList;
    let targetList = response.data && response.data.targetList;
    let verificationFailures = response.data && response.data.verificationFailures;
    let manager = response.data && response.data.manager;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        collaboratorList,
        enabledCollaboratorActions,
        periodList,
        statusList,
        targetTypeList,
        targetList,
        verificationFailures,
        manager,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteGoalsAndObjectivesAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteGoalsAndObjectivesAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Objetivo eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createGoalsAndObjectivesAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createGoalsAndObjectivesAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Objetivo creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyGoalsAndObjectivesAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyGoalsAndObjectivesAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Objetivo actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const verifyGoalsAndObjectivesAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.VERIFY });
  try {
    let response = await verifyGoalsAndObjectivesAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.VERIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.VERIFY_FULFILLED,
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.VERIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getGoalsAndObjectivesTasksAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TASKS });
  try {
    let response = await getGoalsAndObjectivesTasksAPI(`?code=${code}`);

    let taskList = response.data && response.data.taskList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_TASKS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_TASKS_FULFILLED,
      payload: {
        taskList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_TASKS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getGoalsAndObjectivesOneAction = (internalCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TASKS });
  try {
    let response = await getGoalsAndObjectivesOneAPI(`?internalCode=${internalCode}`);

    let taskList = response.data && response.data.taskList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_TASKS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_TASKS_FULFILLED,
      payload: {
        taskList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_TASKS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};