import {
  GET_GEOGRAPHICAL_STRUCTURE_DATA,
  GET_GEOGRAPHICAL_STRUCTURE_DATA_FULFILLED,
  GET_GEOGRAPHICAL_STRUCTURE_DATA_REJECTED,
  GET_GEOGRAPHICAL_OCCUPATION_DATA,
  GET_GEOGRAPHICAL_OCCUPATION_DATA_FULFILLED,
  GET_GEOGRAPHICAL_OCCUPATION_DATA_REJECTED,
  SAVE_GEOGRAPHICAL_STRUCTURE_DATA,
  SAVE_GEOGRAPHICAL_STRUCTURE_DATA_FULFILLED,
  SAVE_GEOGRAPHICAL_STRUCTURE_DATA_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { getGeoStructure, saveGeoStructure, getGeographicalOccupationEndpoint } from "@icarius-connection/api";

export const getGeoStructureAction = () => async (dispatch) => {

  dispatch({ type: GET_GEOGRAPHICAL_STRUCTURE_DATA });
  try {
    let response = await getGeoStructure();
    if (response.data.status === "OK") {

      let orgChart = response.data.result;
      let statistics = response.data.statistics;
      let divisions = response.data.divisions;

      dispatch({
        type: GET_GEOGRAPHICAL_STRUCTURE_DATA_FULFILLED,
        payload: {
          orgChart,
          statistics,
          divisions,
        },
      });

      return response;
    } else {
      dispatch({ type: GET_GEOGRAPHICAL_STRUCTURE_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_GEOGRAPHICAL_STRUCTURE_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getGeographicalOccupation = (node) => async (dispatch) => {

  dispatch({ type: GET_GEOGRAPHICAL_OCCUPATION_DATA });
  try {
    let query = `?code=${node}`;
    let response = await getGeographicalOccupationEndpoint(query);
    if (response.data.status === "OK") {

      let orgChart = response.data.result;

      dispatch({
        type: GET_GEOGRAPHICAL_OCCUPATION_DATA_FULFILLED,
        payload: {
          orgChart,
        },
      });

      return response;
    } else {
      dispatch({ type: GET_GEOGRAPHICAL_OCCUPATION_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: GET_GEOGRAPHICAL_OCCUPATION_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const saveGeoStructureAction = (data) => async (dispatch) => {

  dispatch({ type: SAVE_GEOGRAPHICAL_STRUCTURE_DATA });
  try {
    let response = await saveGeoStructure({ data });
    if (response.data.status === "OK") {

      let orgChart = response.data.result;
      let statistics = response.data.statistics;

      dispatch({
        type: SAVE_GEOGRAPHICAL_STRUCTURE_DATA_FULFILLED,
        payload: {
          orgChart,
          statistics
        },
      });

      return response;
    } else {
      dispatch({ type: SAVE_GEOGRAPHICAL_STRUCTURE_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultError"), severity: "error" }));
    }
  } catch (e) {
    dispatch({ type: SAVE_GEOGRAPHICAL_STRUCTURE_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
