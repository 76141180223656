import React from "react";
import Text from "./text";
import Number from "./number";
import Options from "./options";
import Image from "./Image";
import Checkbox from "./Checkbox";
import { valueIsOfType, valueTypes } from "@icarius-utils/types";
class CustomEditableField extends React.Component {
  render() {
    const {
      label,
      type,
      companyColor,
      options,
      handleTextChange,
      value,
      name,
      useWhiteText,
      required,
      disableInput,
      useMobileSize,
      checkboxData,
      attachImage,
      audioPlayerIcon,
    } = this.props;

    switch (type) {
      case "number":
        return (
          <Number
            disableInput={disableInput}
            required={required}
            useWhiteText={useWhiteText}
            label={label}
            type={type}
            showState
            color={companyColor}
            name={name}
            isValid={valueIsOfType(label, valueTypes.text)}
            onChange={val => handleTextChange(name, val)}
            useMobileSize={useMobileSize}
            audioPlayerIcon={audioPlayerIcon}
          />
        );
      case "text":
      case "textarea":
        return (
          <Text
            disableInput={disableInput}
            required={required}
            useWhiteText={useWhiteText}
            label={label}
            type={type}
            showState
            color={companyColor}
            name={name}
            isValid={valueIsOfType(label, valueTypes.text)}
            onChange={val => handleTextChange(name, val)}
            useMobileSize={useMobileSize}
            isTextArea={type === "textarea"}
            audioPlayerIcon={audioPlayerIcon}
          />
        );
      case "select":
        return (
          <Options
            disableInput={disableInput}
            required={required}
            useWhiteText={useWhiteText}
            label={label}
            type={type}
            opcionesSelect={options}
            color={companyColor}
            name={name}
            onChange={val => handleTextChange(name, val)}
            useMobileSize={useMobileSize}
            audioPlayerIcon={audioPlayerIcon}
          />
        );
      case "check":
        return (
          <Checkbox
            disableInput={disableInput}
            required={required}
            useWhiteText={useWhiteText}
            label={label}
            type={type}
            opcionesSelect={options}
            color={companyColor}
            name={name}
            onChange={val => handleTextChange(name, val)}
            useMobileSize={useMobileSize}
            checkboxData={checkboxData}
            audioPlayerIcon={audioPlayerIcon}
          />
        );
      case "img":
        return (
          <Image
            disableInput={disableInput}
            required={required}
            useWhiteText={useWhiteText}
            label={label}
            type={type}
            color={companyColor}
            name={name}
            onChange={val => handleTextChange(name, val)}
            useMobileSize={useMobileSize}
            attachImage={attachImage}
            value={value}
          />
        );
      default:
        return <div></div>;
    }
  }
}

export default CustomEditableField;
