import { useState } from "react";
import * as yup from 'yup';

const useHandleFormDuplicate = (data, duplicateCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "code") {
        return "";
      }

      if (fieldName === "name") {
        return ""
      }

      if (fieldName === "ref") {
        return data.code
      }

      return data[fieldName];
    }

    const fieldNames = [
      "ref",
      "code",
      "name",
      "group",
      "sequence",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const codeRegex = /^[A-Z]+[A-Z0-9]*$/;

    const schema = yup.object().shape({
      code: yup.string().matches(codeRegex).max(20).required(),
      name: yup.string().max(254).required(),
      group: yup.string().max(100).required(),
      sequence: yup.string().max(3).required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const formDataToSend = { ...formData };

      formDataToSend.sequence = parseInt(formDataToSend.sequence);      

      duplicateCallback(formDataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    if (fieldName === "sequence") {
      return value.length > 0 ? value.replace(/\s/g, "") : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleFormDuplicate;
