import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Checkbox
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createFunctionaryTypeAction, modifyFunctionaryTypeAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, types, requires, handleClose } = props;
  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createFunctionaryTypeAction(dataToSend))
    .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyFunctionaryTypeAction(dataToSend))
    .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  const flexStyle = { height: 50, padding: "0px 10px", display: "flex", alignItems: "center", cursor: "pointer" };

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}            
            label={getLocalizedString("functionaryTypeCode")}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* nombre */}
          <TextField            
            required
            fullWidth
            margin={"none"}            
            label={getLocalizedString("functionaryTypeName")}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>        
        <Grid container item alignItems="center" xs={12} sm={6} md={6} style={gridStyle}> {/* procesos */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-type-${data ? data.internalCode : "new"}`}>{getLocalizedString("functionaryTypeProcess")}</InputLabel>
            <Select      
              multiple  
              value={formData.process}
              labelId={`label-type-${data ? data.internalCode : "new"}`}
              id={`select-type-${data ? data.internalCode : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "process")}
              margin={"none"}
            >
              {
                types && types.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>  
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* requiere cliente */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-requires-${data ? data.internalCode : "new"}`}>{getLocalizedString("functionaryTypeRequiresClient")}</InputLabel>
            <Select        
              value={formData.requires}
              labelId={`label-requires-${data ? data.internalCode : "new"}`}
              id={`select-requires-${data ? data.internalCode : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "requires")}
              margin={"none"}
            >
              {
                requires && requires.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* personal de planta */}
          <div style={flexStyle} onClick={() => setFormValue(!Boolean(formData.plant), "plant")}>
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
              {getLocalizedString("functionaryTypePlant")}
            </Typography>                               
            <Checkbox checked={formData.plant} />
          </div>  
        </Grid>
      </Grid>      
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>        
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>      
    </Grid>
  );
}

export default Form;
