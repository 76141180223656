import * as actionTypes from "./actionTypes";

const initialState = {
  rows: [],
  dateFormat: "yyyy/mm/dd",
  isLoading: false,  
  paramTypes: [],
  listTypes: [],
  requiredTypes: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_STATE:
      return initialState;

    case actionTypes.GET_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
        paramTypes: action.payload.paramTypes,
        listTypes: action.payload.listTypes,
        requiredTypes: action.payload.requiredTypes,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_ROWS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
        paramTypes: action.payload.paramTypes,
        listTypes: action.payload.listTypes,
        requiredTypes: action.payload.requiredTypes,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
        paramTypes: action.payload.paramTypes,
        listTypes: action.payload.listTypes,
        requiredTypes: action.payload.requiredTypes,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
        paramTypes: action.payload.paramTypes,
        listTypes: action.payload.listTypes,
        requiredTypes: action.payload.requiredTypes,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DUPLICATE:
      return { ...state, isLoading: true };
    case actionTypes.DUPLICATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
        paramTypes: action.payload.paramTypes,
        listTypes: action.payload.listTypes,
        requiredTypes: action.payload.requiredTypes,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.DUPLICATE_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
