import {
  GET_GENERAL_SETTINGS_ROWS,
  GET_GENERAL_SETTINGS_ROWS_FULFILLED,
  GET_GENERAL_SETTINGS_ROWS_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getGeneralSettingsAPI, updateGeneralSettingsAPI } from "@icarius-connection/api";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "INVALID_IMAGE_TYPE":
    case "INVALID_FILE":
      errorString = getLocalizedString("invalidFile");
      break;
    case "IMAGE_SIZE_EXCEEDED":
      errorString = getLocalizedString("imageSizeExceeded");
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getGeneralSettingsAction = () => async (dispatch) => {
  dispatch({ type: GET_GENERAL_SETTINGS_ROWS });
  try {
    let response = await getGeneralSettingsAPI();
    let result = response.data && response.data.formattedResult;

    dispatch({
      type: GET_GENERAL_SETTINGS_ROWS_FULFILLED,
      payload: {
        generalSettings: result,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_GENERAL_SETTINGS_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};


export const updateGeneralSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: GET_GENERAL_SETTINGS_ROWS });
  try {
    let response = await updateGeneralSettingsAPI(data);
    let result = response.data && response.data.formattedResult;

    dispatch({
      type: GET_GENERAL_SETTINGS_ROWS_FULFILLED,
      payload: {
        generalSettings: result,
      },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("dataSucessfullySaved"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: GET_GENERAL_SETTINGS_ROWS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};