import React from "react";
import Masonry from 'react-masonry-css';
import Group from "./group";

const BREAKPOINTS = {
    default: 3,
    1920: 4,
    1440: 3,
    1280: 3,
    960: 2,
    750: 2,
    600: 1,
    480: 1
}

const GroupList = React.memo((props) => {

    const {
        tags,
        groups,
        handleOpenEditGroupDialog,
        handleOpenDeleteGroupDialog,
        handleOpenLeaveGroupDialog,
        handleOpenMembersDialog,
        handleOpenMailDialog,
    } = props;

    return (
        <div className="flex flex-wrap w-full">
            <Masonry
                breakpointCols={BREAKPOINTS}
                className="my-masonry-grid flex w-full"
                columnClassName="my-masonry-grid_column flex flex-col p-8 py-0">
                {
                    groups.map((group) => {
                        return (
                            <div key={group.id} className={"mb-16"}>
                                <Group
                                    tags={tags}
                                    data={group}
                                    handleOpenEditGroupDialog={handleOpenEditGroupDialog}
                                    handleOpenDeleteGroupDialog={handleOpenDeleteGroupDialog}
                                    handleOpenLeaveGroupDialog={handleOpenLeaveGroupDialog}
                                    handleOpenMembersDialog={handleOpenMembersDialog}
                                    handleOpenMailDialog={handleOpenMailDialog}
                                />
                            </div>
                        )
                    })
                }
            </Masonry>
        </div>
    )
})

export default GroupList;