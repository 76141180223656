import React, { useMemo } from "react";
import { Chip, Tooltip } from '@material-ui/core';

const TagList = ({ tags, values, handleDelete }) => {

  const groupTags = useMemo(() => {
    if (!values?.length || !tags?.length) return [];
    return tags.filter((item) => values.includes(item.key));
  }, [tags, values])

  return (
    <>
      {
        groupTags.map((item) => {
          return (
            <Tooltip
              key={item.key}
              title={item.value}
              arrow
            >
              <Chip
                label={item.value}
                onDelete={handleDelete ? () => handleDelete(item.key) : null}
                classes={{
                  root: "h-24 br-6 label-width",
                  outlined: "whiteText-border",
                  label: "px-6 py-4",
                  deleteIcon: "w-16 ml-0 whiteText",
                }}
                className="whiteText"
                variant="outlined"
                style={{ borderColor: "white !important" }}
              />
            </Tooltip>
          )
        })
      }
    </>
  )
}

export default TagList;