import React, { useMemo, useState } from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import { CheckIcon, SearchIcon } from "@icarius-icons/index";
import { useSelector } from "react-redux";
import { getAppColor, getUserData } from "src/app/selectors";
import {
  Typography,
  Grid,
  Divider,
  TextField,
  InputAdornment,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import ProfileImg from "@icarius-common/profileImg";
import ListAltIcon from '@material-ui/icons/ListAlt';
import useHandleUserSelection from "@icarius-common/userSelectionDialog/useHandleUserSelection";
import useGetScreenWidth from "@icarius-utils/hooks/useGetScreenWidth";
import UserSection from "./userSection";
import Objective from "./objective";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import ChartsContainer from "../graphsDialog/chartsContainer";
import { getTheme } from "@icarius-pages/login/selectors";

const UserView = (props) => {

  const {
    isLoading,
    level,
    data,
    people,
    manager,
    periodObject,
    canOperate,
    periodSelector,
    handlers,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [showAll, setShowAll] = useState(true);

  const { code } = useSelector(getUserData);
  const screenWidth = useGetScreenWidth();
  const color = useSelector(getAppColor);
  const { theme } = useSelector(getTheme);

  const formattedAndFilteredPeople = useMemo(() => {
    let auxArray = people
      .filter((item) => data.some((dataItem) => dataItem.code === item.key)) //si algun registro de data tiene a esa persona
      .map((item) => {
        return {
          ...item,
          name: item.value,
        }
      });

    // poner primero al user conectado (si es que está)
    const index = auxArray.findIndex((item) => item.key === code)
    const ownUserData = auxArray[index];
    if (index !== -1) {
      auxArray.splice(index, 1);
      auxArray = [ownUserData, ...auxArray]
    }

    // filtrar segun campo de busqueda
    if (!searchValue) return auxArray;

    return [...auxArray].filter((item) => {
      return item.name && item.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [data, people, code, searchValue])

  const {
    keysSelected,
    checkedList,
    handleSelection,
    resetUsersSelected,
  } = useHandleUserSelection(formattedAndFilteredPeople, level === 'C' ? [code] : [], true);

  const createButton = () => canOperate && (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={handlers.handleOpenCreateDialog}
      type={"add"}
    />
  )

  const gridViewButton = () => (
    <CustomIconButton
      title={'Ver grilla'}
      onClick={handlers.handleOpenGrid}
    >
      <ListAltIcon />
    </CustomIconButton>
  )

  const verifyButton = () => (
    <CustomIconButton
      title={'Verificar ponderados'}
      onClick={() => handlers.handleVerifyByUser(keysSelected)}
    >
      <CheckIcon />
    </CustomIconButton>
  )

  const objectivesFromUserSelected = data.filter((item) => {
    if (item.code !== keysSelected[0]) return false;

    // filtrar segun el switch
    if (showAll) return true;
    return item.assigner === code;
  });

  return (
    <CommonPage
      isLoading={isLoading}
      title={level === 'C' ? 'Mis metas y objetivos' : 'Metas y objetivos'}
      isNotGridPage
    >
      <div style={{ padding: 24, paddingTop: 0 }}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 20, minHeight: 60 }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 30 }}>
              <div style={{ minWidth: 300, maxWidth: 400 }}>
                {periodSelector}
              </div>
              {
                Boolean(manager) &&
                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 10 }}>
                  <ProfileImg image={manager.image} size={40} />
                  <div>
                    <Typography className="whiteText">Mi manager:</Typography>
                    <Typography className="whiteText" style={{ fontWeight: 600 }}>{manager.name}</Typography>
                  </div>
                </div>
              }
            </div>
            <div style={{ display: 'flex', justifyContent: "flex-end" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showAll}
                    onChange={() => setShowAll(prev => !prev)}
                    value={showAll}
                    color="primary"
                  />
                }
                label={showAll ? 'Todos los objetivos' : 'Objetivos que he asignado'}
                labelPlacement="start"
                style={{ margin: 0 }}
              />
              {
                [createButton, verifyButton, gridViewButton]
                  .map((item, index) => <div key={index}>{item()}</div>)
              }
            </div>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <Divider style={{ margin: "20px 0px", background: color, height: 1 }} />
        </div>
        <div>
          {
            Boolean(periodObject && periodObject?.from && periodObject?.to) &&
            <Typography className="whiteText" style={{ fontSize: 18, fontWeight: 600 }}>
              {`Desde el ${periodObject?.from || ''} hasta el ${periodObject?.to || ''}:`}
            </Typography>
          }
          {
            Boolean(formattedAndFilteredPeople.length) && level !== 'C' &&
            <Grid container item xs={12} style={{ margin: '10px 0px' }} spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <div style={{ display: "flex", alignItems: "center", height: 48 }}>
                  <TextField
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      resetUsersSelected();
                    }}
                    placeholder={"Buscar colaborador"}
                    style={{ borderBottom: `1px solid ${theme === "dark" ? "#747474" : "#949494"}` }}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <UserSection
                employees={checkedList}
                handleClick={handleSelection}
                screenWidth={screenWidth - (screenWidth < 1280 ? 60 : 120)}
                isSelection={true}
              />
            </Grid>
          }
          {
            !Boolean(formattedAndFilteredPeople.length) && !isLoading &&
            <Typography className="whiteText" align="center" style={{ padding: 100, fontSize: 24 }}>
              {`${level === 'C' ? 'No' : 'Ningún colaborador'} posee metas y objetivos para el período seleccionado`}
            </Typography>
          }
          {
            Boolean(formattedAndFilteredPeople.length) && !Boolean(keysSelected.length) && !isLoading &&
            <Typography className="whiteText" align="center" style={{ padding: 50, fontSize: 24 }}>
              Seleccione un colaborador
            </Typography>
          }
          {
            Boolean(formattedAndFilteredPeople.length && keysSelected.length && !objectivesFromUserSelected.length) && !isLoading &&
            <Typography className="whiteText" align="center" style={{ padding: 50, fontSize: 24 }}>
              El colaborador no posee metas y objetivos para el período seleccionado
            </Typography>
          }
          {
            Boolean(keysSelected.length && objectivesFromUserSelected.length) &&
            <>
              <Grid container item xs={12} spacing={2} alignItems="flex-start" justify="center">
                {
                  [
                    {
                      title: 'Objetivos personales',
                      data: objectivesFromUserSelected.filter((item) => item.targetType === 'Personal'),
                    },
                    {
                      title: 'Objetivos de la organización',
                      data: objectivesFromUserSelected.filter((item) => item.targetType !== 'Personal'),
                    },
                  ].map((item, index) => {
                    return (
                      <Grid key={index} container item xs={12} md={6} spacing={2}>
                        <Typography className="whiteText" style={{ fontSize: 20, margin: 16, marginBottom: 0 }}>{item.title}</Typography>
                        {
                          !Boolean(item.data.length) &&
                          <Typography className="whiteText" align="center" style={{ padding: 50, fontSize: 24 }}>
                            El colaborador seleccionado no posee metas y objetivos de este tipo
                          </Typography>
                        }
                        {
                          item.data
                            .sort((a, b) => {
                              const aDate = createDateFromDDMMYYYY(a.startDate);
                              const bDate = createDateFromDDMMYYYY(b.startDate);

                              return aDate - bDate || a.targetName.localeCompare(b.targetName);
                            })
                            .map((dataItem) => {
                              return (
                                <Grid key={dataItem.internalCode} container item xs={12}>
                                  <Objective
                                    data={dataItem}
                                    canOperate={canOperate}
                                    handlers={handlers}
                                  />
                                </Grid>
                              )
                            })
                        }
                      </Grid>
                    )
                  })
                }
                <Typography className="whiteText" style={{ fontSize: 20, margin: 16 }}>
                  {`Gráficos de las metas y objetivos: ${periodObject?.value}`}
                </Typography>
                <ChartsContainer
                  data={objectivesFromUserSelected}
                />
              </Grid>
            </>
          }
        </div>
      </div>
    </CommonPage>
  )
}

export default UserView;