import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getLoading, getRows } from "../selectors";
import { Grid } from "@material-ui/core";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import DataItem from "./data/dataItem";
import { getFunctionsSettingsAction } from "../actions";
import { RESET_STATE } from "../actionTypes";
import paths from "@icarius-localization/paths";

const FunctionsSettings = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFunctionsSettingsAction());
    return () => dispatch({ type: RESET_STATE })
  }, [dispatch])

  const rows = useSelector(getRows);
  const isLoading = useSelector(getLoading);

  const getDataIsEmpty = () => {
    return !rows || rows.length === 0;
  }

  const handleGoBack = () => {
    history.push(paths.organizationalDefinitions);
  }

  return (
    <ABMLayout
      title={getLocalizedString("functionsSettings")}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={getLocalizedString("newFunction")}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showNoData={getDataIsEmpty()}
      noDataTitle={getLocalizedString("noFunctionsFound")}
      noDataCreateTitle={getLocalizedString("newFunction")}
      noDataOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
    >
      <Grid container item xs={12} direction="row">
        {
          createIsOpen &&
          <Grid container item xs={12} style={{ margin: "10px 0px" }}>
            <DataItem
              data={null}
              closeNew={() => setCreateIsOpen(false)}
            />
          </Grid>
        }
        {
          rows && rows.map(item => {
            return (
              <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.internalCode}>
                <DataItem data={item} />
              </Grid>
            )
          })
        }
      </Grid>
    </ABMLayout>
  );
}

export default FunctionsSettings;
