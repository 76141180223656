import * as actionTypes from "./actionTypes";

export const initialState = {
  data: [],
  isLoading: false,
  selectedGroup: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case "OPEN_MEMBERS_DIALOG":
      return { ...state, membersDialogIsOpen: true, selectedGroup: action.payload };
    case "CLOSE_MEMBERS_DIALOG":
      return { ...state, membersDialogIsOpen: false, selectedGroup: null };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default reducer;