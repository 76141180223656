import { useState } from "react";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = ["code", "name"];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = () => {
    const codeRegex = /^[A-Za-z]+[a-zA-Z0-9_]*$/;
    const { code, name } = formData;

    if (!code || code === "" || !codeRegex.test(code)) return false;
    if (!name || name === "") return false;

    return true;
  }

  const submit = () => {
    if (dataIsValid()) {
      isCreate ? createCallback(formData) : modifyCallback({ ...formData, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
