import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  groups: [],
  users: [],
  tags: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_GROUPS_DATA:
      return { ...state, isLoading: true };
    case actionTypes.GET_GROUPS_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        groups: action.payload.groups,
        users: action.payload.users,
        tags: action.payload.tags,
      };
    case actionTypes.GET_GROUPS_DATA_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE_GROUP:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_GROUP_FULFILLED: {
      let newGroups = [...state.groups, action.payload.group];
      return { ...state, isLoading: false, groups: newGroups };
    }
    case actionTypes.CREATE_GROUP_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.EDIT_GROUP:
      return { ...state, isLoading: true };
    case actionTypes.EDIT_GROUP_FULFILLED: {
      let newGroups = [...state.groups];
      const editedGroup = action.payload.group;
      const index = newGroups.findIndex((group) => group.id === editedGroup.id);
      
      if (index > -1) {
        newGroups[index] = editedGroup;
      }

      return { ...state, isLoading: false, groups: newGroups };
    }
    case actionTypes.EDIT_GROUP_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE_GROUP:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_GROUP_FULFILLED: {
      let newGroups = [...state.groups].filter((group) => group.id !== action.payload.id);

      return { ...state, isLoading: false, groups: newGroups };
    }
    case actionTypes.DELETE_GROUP_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.LEAVE_GROUP:
      return { ...state, isLoading: true };
    case actionTypes.LEAVE_GROUP_FULFILLED: {
      let newGroups = [...state.groups].filter((group) => group.id !== action.payload.id);

      return { ...state, isLoading: false, groups: newGroups };
    }
    case actionTypes.LEAVE_GROUP_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CLEAN_STATE:
      return initialState;
    default:
      return state;
  }
}
