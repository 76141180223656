import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";

export default [
    {
        pages: ["geographicalOccupation"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["geographicalOccupation"],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "Personal de planta",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["geographicalOccupation"],
        config: {
            headerName: getLocalizedString("region"),
            field: "Region",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["geographicalOccupation"],
        config: {
            headerName: getLocalizedString("provicia"),
            field: "Provincia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["geographicalOccupation"],
        config: {
            headerName: getLocalizedString("comuna"),
            field: "Comuna",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.geographicalStructure],
        config: {
            headerName: "Código de región",
            field: "Código de region",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.geographicalStructure],
        config: {
            headerName: "Región",
            field: "Region",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.geographicalStructure],
        config: {
            headerName: "Código de provincia",
            field: "Código de provincia",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.geographicalStructure],
        config: {
            headerName: "Provincia",
            field: "Provincia",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.geographicalStructure],
        config: {
            headerName: "Código de comuna",
            field: "Código de comuna",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.geographicalStructure],
        config: {
            headerName: "Comuna",
            field: "Comuna",
            filter: "agSetColumnFilter",
        }
    },
]