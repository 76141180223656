import React, { useState } from 'react';
import {
  Popover,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from "@icarius-icons";
import ButtonDialogAction from '@icarius-common/buttonDialogAction';

const TagsInput = ({ tags, values, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const handleToggleLabel = (tagKey) => {
    let newTagList = [...values];

    if (newTagList.includes(tagKey)) {
      newTagList = newTagList.filter((item) => item !== tagKey);
    } else {
      newTagList.push(tagKey);
    }

    onChange(newTagList);
  }

  return (
    <>
      <ButtonDialogAction
        onClick={handleMenuClick}
        text={'Etiquetar'}
      />
      <Popover
        hideBackdrop={true}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className="pointer-events-none"
        classes={{
          paper: "pointer-events-auto py-8 prevent-add-close dialog-container"
        }}
      >
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleMenuClose}>
          <List>
            {
              tags?.map((item) => {
                return (
                  <ListItem
                    key={item.key}
                    onClick={() => handleToggleLabel(item.key)}
                    button
                    dense
                  >
                    {
                      values.includes(item.key)
                        ? <CheckBoxIcon className="list-item-icon text-16 whiteText" color="action" />
                        : <CheckBoxOutlineBlankIcon className="list-item-icon text-16 whiteText" color="action" />
                    }
                    <ListItemText className="truncate pl-8 whiteText" primary={item.value} disableTypography={true} />
                  </ListItem>
                )
              })
            }
          </List>
        </ClickAwayListener>
      </Popover>
    </>
  );
}

export default TagsInput;