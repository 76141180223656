import {
  getGroupsDataAPI,
  createGroupAPI,
  editGroupAPI,
  deleteGroupAPI,
  leaveGroupAPI,
} from "@icarius-connection/api";
import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response.data.status) {
    case "INVALID_PARTICIPANTS":
      errorString = getLocalizedString("groupInvalidParticipants");
      break;
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("groupValidationError");
      break;
    case "INVALID_GROUP":
      errorString = getLocalizedString("groupInvalidGroup");
      break;
    case "UNAUTHORIZED":
      errorString = getLocalizedString("groupUnauth");
      break;
    case "IN_USE":
      errorString = "No es posible eliminar el grupo, está relacionado con acciones y recordatorios";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getGroupsDataAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_GROUPS_DATA });
  try {
    let response = await getGroupsDataAPI();
    let groups = response.data && response.data.groups;
    let users = response.data && response.data.societyPeople;
    let tags = response.data && response.data.tags;

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_GROUPS_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    if (groups.length) {
      groups = groups.filter((group) => group.isMemberOfGroup);
    }

    dispatch({
      type: actionTypes.GET_GROUPS_DATA_FULFILLED,
      payload: {
        groups,
        users,
        tags,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_GROUPS_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createGroupAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_GROUP });
  try {
    let response = await createGroupAPI(data);
    let status = response.data && response.data.status;
    let group = response.data.result;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_GROUP_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_GROUP_FULFILLED,
      payload: {
        group,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Grupo creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch);
    dispatch({ type: actionTypes.CREATE_GROUP_REJECTED, payload: e });
  }
};

export const editGroupAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.EDIT_GROUP });
  try {
    let response = await editGroupAPI(data);
    let status = response.data && response.data.status;
    let group = response.data.result;

    if (status !== "OK") {
      dispatch({ type: actionTypes.EDIT_GROUP_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.EDIT_GROUP_FULFILLED,
      payload: {
        group,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Grupo editado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch);
    dispatch({ type: actionTypes.EDIT_GROUP_REJECTED, payload: e });
  }
};

export const deleteGroupAction = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_GROUP });
  try {
    let response = await deleteGroupAPI({ id });
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_GROUP_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_GROUP_FULFILLED,
      payload: {
        id,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Grupo eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch);
    dispatch({ type: actionTypes.DELETE_GROUP_REJECTED, payload: e });
  }
};

export const leaveGroupAction = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.LEAVE_GROUP });
  try {
    let response = await leaveGroupAPI({ id });
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.LEAVE_GROUP_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.LEAVE_GROUP_FULFILLED,
      payload: {
        id,
      },
    });

    dispatch(openSnackbarAction({ msg: 'Grupo abandonado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch);
    dispatch({ type: actionTypes.LEAVE_GROUP_REJECTED, payload: e });
  }
};