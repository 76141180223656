import React from "react";
import { compose, withProps, withHandlers, withStateHandlers } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer,
    InfoWindow
} from "react-google-maps";
import Spiderfy from "./spiderfy";
import { Grid } from "@material-ui/core";
import MultipleItemTable from "./multipleItemTable";
import SingleItemTable from "./singleItemTable";
import { BASE_URL } from "@icarius-connection/endpoints";
import { GOOGLE_MAPS_KEY } from "@icarius-utils/properties";

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&v=3.exp&language=es&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: "calc(100vh - 195px)" }} />,
        containerElement: <div style={{ height: "calc(100vh - 195px)" }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withStateHandlers(
        ({ initialArray = [] }) => ({
            openArray: initialArray,
            directions: initialArray
        }),
        {
            setDefault: () => (length) => ({
                openArray: [...Array(length).fill(false)],
                directions: [...Array(length).fill({})],
            }),
            setOpen: ({ openArray }) => (pos) => ({
                openArray: openArray.map(function (item, i) { return pos === i ? !item : item; })
            }),
            setDirections: ({ directions }) => (pos, newDirections) => ({
                directions: directions.map(function (item, i) { return pos === i ? newDirections : item; })
            }),
        }),
    withHandlers(() => {
        let refs = {
            map: undefined,
        }

        return {
            onMapMounted: () => (newArray, ref) => {
                if (refs.map) return;
                refs.map = ref
                const data = newArray
                let bounds = new window.google.maps.LatLngBounds();

                if (data[0]["map_data"]["latitude"] && data[0]["map_data"]["longitude"]) {
                    for (let i = 0; i < data.length; i++) {
                        const { map_data } = data[i];
                        const position = {
                            lat: parseFloat(map_data["latitude"]), lng: parseFloat(map_data["longitude"])
                        }
                        bounds.extend(position);
                    }
                } else {
                    const { map_data } = data[0];

                    const startPosition = {
                        lat: parseFloat(map_data["start_latitude"]), lng: parseFloat(map_data["start_longitude"])
                    }
                    bounds.extend(startPosition);

                    for (let i = 0; i < data.length; i++) {
                        const { map_data } = data[i];
                        const position = {
                            lat: parseFloat(map_data["end_latitude"]), lng: parseFloat(map_data["end_longitude"])
                        }
                        bounds.extend(position);
                    }
                }

                // Don't zoom in too far on only one marker
                if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
                    let extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.001, bounds.getNorthEast().lng() + 0.001);
                    let extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.001, bounds.getNorthEast().lng() - 0.001);
                    bounds.extend(extendPoint1);
                    bounds.extend(extendPoint2);
                }

                refs.map.fitBounds(bounds);

            },
        }
    }),
    withScriptjs,
    withGoogleMap
)((props) => {

    const {
        data,
        filters,
        columnSelected,
        nmark,
        openArray,
        directions,
        setDefault,
        setDirections,
        setOpen,
        color,
        onMapMounted,
        onBoundsChanged,
    } = props;

    if (data.length > 0 && openArray.length === 0) {
        setDefault(data.length)
    }

    let newArray = data.length > 0 ? data.filter(marker => marker.map_data) : [];
    const DirectionsService = new window.google.maps.DirectionsService();
    const array = newArray.map((marker, index) => {
        const {
            title,
            latitude,
            start_latitude,
            start_longitude,
            end_latitude,
            end_longitude,
            longitude,
            icon,
            popup_grid,
        } = marker.map_data;

        const iconToUse = (icon != null && icon !== "" ?
            {
                url: `${BASE_URL}/images/KPI/light/${icon}`,
                scaledSize: new window.google.maps.Size(30, 30),
                labelOrigin: new window.google.maps.Point(16, 39),
                size: new window.google.maps.Size(30, 30),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(11, 40),
            }
            : {
                url: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Google_Maps_pin.svg",
                labelOrigin: new window.google.maps.Point(11, 42),
                size: new window.google.maps.Size(22, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(11, 40),
            });

        const arrayOfString = [...new Set(popup_grid.map(item => item[columnSelected]).filter(item => Boolean(item)))];
        let labelString = arrayOfString.join(" - ");
        labelString = labelString.length > 60 ? `${labelString.substring(0, 60)}...` : labelString;

        const label = {
            color: 'black',
            fontWeight: 'bold',
            text: (columnSelected && arrayOfString.length > 0) ? labelString : title,
        }

        if (latitude && longitude) {
            return (
                <Marker
                    key={title}
                    icon={iconToUse}
                    label={nmark === "Y" ? label : null}
                    position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                >
                    {
                        openArray[index] &&
                        <InfoWindow onCloseClick={() => setOpen(index)}>
                            {
                                popup_grid.length > 0 ?
                                    (
                                        popup_grid.length === 1 ?
                                            <SingleItemTable
                                                data={popup_grid[0]}
                                                title={title}
                                                color={color}
                                                filters={filters}
                                                overTitle={marker.alternate_title}
                                            />
                                            :
                                            <MultipleItemTable
                                                data={popup_grid}
                                                filters={filters}
                                                title={title}
                                                color={color}
                                                overTitle={marker.alternate_title}
                                            />
                                    )
                                    : <Grid container>
                                        <Grid item>
                                            <div style={{ borderBottom: `1px solid ${color}` }}>
                                                <p>{title}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                            }
                        </InfoWindow>
                    }
                </Marker>
            )
        } else {
            if (start_latitude && start_longitude && end_latitude && end_longitude && props.directions.length > 0 && !Object.keys(props.directions[index]).length) {
                DirectionsService.route({
                    origin: new window.google.maps.LatLng(parseFloat(start_latitude), parseFloat(start_longitude)),
                    destination: new window.google.maps.LatLng(parseFloat(end_latitude), parseFloat(end_longitude)),
                    travelMode: window.google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirections(index, result)
                    }
                });
            }
        }

        return (
            <Marker
                key={index}
                icon={iconToUse}
                label={nmark === "Y" ? label : null}
                position={{ lat: parseFloat(end_latitude), lng: parseFloat(end_longitude) }}
            >
                {
                    openArray[index] &&
                    <InfoWindow onCloseClick={() => setOpen(index)}>
                        {
                            popup_grid.length > 0 ?
                                (
                                    popup_grid.length === 1 ?
                                        <SingleItemTable
                                            data={popup_grid[0]}
                                            title={title}
                                            color={color}
                                            filters={filters}
                                            overTitle={marker.alternate_title}
                                            directions={props.directions}
                                            index={index}
                                        />
                                        :
                                        <MultipleItemTable
                                            data={popup_grid}
                                            filters={filters}
                                            title={title}
                                            color={color}
                                            overTitle={marker.alternate_title}
                                        />
                                )
                                : <Grid container>
                                    <Grid item>
                                        <div style={{ borderBottom: `1px solid ${color}` }}>
                                            <p>{title}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                        }
                    </InfoWindow>
                }
            </Marker>
        )
    });

    const rendererOptions = {
        suppressMarkers: true,
        suppressInfoWindows: true,
    }

    return (
        <GoogleMap
            defaultCenter={{ lat: 0, lng: 0 }}
            ref={(ref) => onMapMounted(newArray, ref)}
            defaultZoom={8}
            onBoundsChanged={onBoundsChanged}>
            {
                (newArray.length > 0 && newArray[0]["map_data"]["start_latitude"] && newArray[0]["map_data"]["start_longitude"]) &&
                <Marker
                    position={{
                        lat: parseFloat(newArray[0]["map_data"]["start_latitude"]),
                        lng: parseFloat(newArray[0]["map_data"]["start_longitude"])
                    }}
                />
            }
            <Spiderfy onSpiderClick={(index) => setOpen(index)}>
                {array}
            </Spiderfy>
            {
                directions?.map((directionsItem, index) => Object.keys(directionsItem).length > 0 &&
                    <DirectionsRenderer
                        key={index}
                        options={rendererOptions}
                        directions={directionsItem} />
                )
            }
        </GoogleMap>
    )
}
);

export default MyMapComponent;