import React from 'react';
import { getLocalizedString } from "@icarius-localization/strings";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { CloseIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import Input from "./input";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

const DetailsEditDialog = ({ geodivisionStrings, dialogIsOpen, editMode, currentNode, handleChangeField, cancelRefClick, closeRefClick, saveRefClick }) => {

  const getTitle = () => {
    const part = currentNode && currentNode["tags"] && currentNode["tags"].includes("Provincia") ? geodivisionStrings?.secondLevel.toLowerCase() : currentNode && currentNode["tags"] && currentNode["tags"].includes("Comuna") ? geodivisionStrings?.thirdLevel.toLowerCase() : geodivisionStrings?.firstLevel.toLowerCase();

    if (editMode) return `Editar ${part}`;
    return `Ver detalle de ${part}`;
  }

  return (
    <Dialog
      open={dialogIsOpen}
      PaperComponent={PaperDraggable}
      maxWidth={"xs"}
      fullWidth={true}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getTitle()}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={editMode ? cancelRefClick : closeRefClick} />
        <DialogContent>
          {
            currentNode &&
            Object.keys(currentNode).map(e => (e === "Nombre" || e === "Código") &&
              <Input
                key={e}
                handleChangeField={handleChangeField}
                editMode={editMode}
                label={e}
                currentNode={currentNode}
                isAssistantField={false}
              />
            )
          }
        </DialogContent>
        <DialogActions>
          {
            editMode &&
            <ButtonDialogAction onClick={cancelRefClick} text={getLocalizedString("cancel")} />
          }
          {
            !editMode &&
            <ButtonDialogAction onClick={closeRefClick} text={getLocalizedString("close")} />
          }
          {
            editMode &&
            <ButtonDialogAction onClick={saveRefClick} isAccept text={getLocalizedString("agree")} />
          }
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default DetailsEditDialog;