import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { loading, getGeneralSettings } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { getGeneralSettingsAction, updateGeneralSettingsAction } from "../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { Form } from "react-final-form";
import { getLocalizedString } from "@icarius-localization/strings";
import Formulario from "./form";
import { SaveIcon } from "@icarius-icons";
import CommonPage from "@icarius-common/commonPage";
import { getInitialDataAction } from "src/app/actions";
import ChangeColorDialog from "./changeColorDialog";
import ColorizeIcon from '@material-ui/icons/Colorize';

const MAX_LENGTH_LONG = 250;

const GeneralSettings = () => {

  const dispatch = useDispatch();
  const isLoading = useSelector(loading);
  const generalSettings = useSelector(getGeneralSettings);
  const color = useSelector(getAppColor);

  const [changeColorDialogIsOpen, setChangeColorDialogIsOpen] = useState(false);

  useEffect(() => {
    dispatch(getGeneralSettingsAction());
  }, [dispatch])

  const fileIsValidImage = (file) => {
    return (
      file.type.includes("/png") ||
      file.type.includes("/jpg") ||
      file.type.includes("/jpeg") ||
      file.type.includes("/bmp") ||
      file.type.includes("/gif")
    )
  }

  const submit = (values) => {
    let settings = {};

    if (generalSettings) {
      Object.keys(generalSettings).forEach(key => {
        generalSettings[key].forEach(g => {
          Object.keys(g).forEach(innerKey => {
            const propName = Object.keys(g[innerKey])[0];
            settings[propName] = values[propName] || "";
          });
        })
      });
    }

    if (valuesAreValid(settings)) {
      dispatch(updateGeneralSettingsAction(settings))
        .then(e => e && e.data && e.data.status && e.data.status === "OK" && dispatch(getInitialDataAction()));
    }
  };

  const valuesAreValid = (values) => {
    let errorMessage = "";
    if (!(values["CLIENTE"].length > 0 && values["CLIENTE"].length <= MAX_LENGTH_LONG)) {
      errorMessage = getLocalizedString("fillClientGeneralSettings")
    }

    if (values["STOP"] === true) {
      if (!(values["STOPMSG"].length > 0 && values["STOPMSG"].length <= MAX_LENGTH_LONG)) {
        errorMessage = getLocalizedString("fillStopMsgGeneralSettings")
      }

      if (!(values["STOPTIME"].length > 0 && values["STOPTIME"].length <= MAX_LENGTH_LONG)) {
        errorMessage = getLocalizedString("fillStopTimeGeneralSettings")
      }
    }

    if (!(values["EMAILRRHH"].length > 0 && values["EMAILRRHH"].length <= MAX_LENGTH_LONG)) {
      errorMessage = getLocalizedString("fillHREmailGeneralSettings")
    }

    if (!(values["GO_DTO_RRHH"].length > 0 && values["GO_DTO_RRHH"].length <= MAX_LENGTH_LONG)) {
      errorMessage = getLocalizedString("fillHRDTOGeneralSettings")
    }

    if (!(values["GO_EMAIL"].length > 0 && values["GO_EMAIL"].length <= MAX_LENGTH_LONG)) {
      errorMessage = getLocalizedString("fillContactEmailGeneralSettings")
    }

    const PORMAXANT_regex = /^((100)|(\d{1,2}))%?$/;
    if (!(values["PORMAXANT"].length > 0 && values["PORMAXANT"].length <= 4 && PORMAXANT_regex.test(values["PORMAXANT"]))) {
      errorMessage = getLocalizedString("fillMaxPercentageAdvance")
    }

    if (values["FIRMADIGITAL"] === true) {
      if (values["DISCLAMER"].length === 0) {
        errorMessage = getLocalizedString("fillDisclaimerGeneralSettings")
      }
      if (!(values["FIRMACOL"].length > 0 && values["FIRMACOL"].length <= MAX_LENGTH_LONG)) {
        errorMessage = getLocalizedString("fillFirmaColGeneralSettings")
      }
    }

    if (values["GEO_DIVISION1"].length === 0) {
      errorMessage = "El primer nivel de organización geográfica es obligatorio"
    }

    if (values["GEO_DIVISION2"].length === 0) {
      errorMessage = "El segundo nivel de organización geográfica es obligatorio"
    }

    if (!(values["GEO_DIVISION1"].length > 0 && values["GEO_DIVISION1"].length <= MAX_LENGTH_LONG)) {
      errorMessage = "Verifique el primer nivel de organización geográfica"
    }

    if (!(values["GEO_DIVISION2"].length > 0 && values["GEO_DIVISION2"].length <= MAX_LENGTH_LONG)) {
      errorMessage = "Verifique el segundo nivel de organización geográfica"
    }

    if (values["GEO_DIVISION3"]?.length > 0 && values["GEO_DIVISION3"]?.length >= MAX_LENGTH_LONG) {
      errorMessage = "Verifique el tercer nivel de organización geográfica"
    }


    if (errorMessage === "") return true;

    dispatch(openSnackbarAction({ msg: errorMessage, severity: "error" }));
    return false;
  };

  const attachImage = (name, originalFile, file, fileName, fileExtension, ogFile) => {
    if (ogFile.size > 5 * 1024 * 1024) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("imageSizeExceeded"), severity: "error" }));
      return;
    }
    if (!fileIsValidImage(ogFile)) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fileMustBeImage"), severity: "error" }));
      return;
    }
    window.setFormValue(name, originalFile);
  }

  const handleTextChange = (name, value) => {
    window.setFormValue(name, value);
  };

  let initialValues = useMemo(() => {
    const auxValues = {};

    if (generalSettings) {
      Object.keys(generalSettings).forEach(key => {
        generalSettings[key].forEach(g => {
          Object.keys(g).forEach(innerKey => {
            const propName = Object.keys(g[innerKey])[0];
            if (g[innerKey][propName] && g[innerKey] && g[innerKey][propName] && g[innerKey][propName]["value"] !== undefined) {
              auxValues[propName] = g[innerKey][propName]["value"];
            }
          });
        })
      });
    }
    return auxValues;
  }, [generalSettings])

  return (
    <CommonPage
      isNotGridPage
      isLoading={isLoading}
      title={getLocalizedString("generalSettings")}
    >
      {
        !Boolean(generalSettings) ? null :
          <div id={"main-content"} style={{ padding: 50, paddingTop: 30 }}>
            <Form
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              initialValues={initialValues}
              onSubmit={submit}
              render={({ handleSubmit, form, values }) => {
                window.setFormValue = form.mutators.setValue;
                return (
                  <form onSubmit={handleSubmit} style={{ width: "100%" }} noValidate>
                    {
                      generalSettings?.activationDeactivation ?
                        <Formulario
                          name={"activationDeactivation"}
                          title={getLocalizedString("activationDeactivation")}
                          color={color}
                          fields={generalSettings.activationDeactivation}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.requests ?
                        <Formulario
                          name={"requests"}
                          title={getLocalizedString("requests")}
                          color={color}
                          fields={generalSettings.requests}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.identificationAndSocietyData ?
                        <Formulario
                          name={"identificationAndSocietyData"}
                          title={getLocalizedString("identificationAndSocietyData")}
                          color={color}
                          fields={generalSettings.identificationAndSocietyData}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    } {
                      generalSettings?.decimalFieldsDefinition ?
                        <Formulario
                          subtitle={"Decimales (0 a 5)"}
                          name={"decimalFieldsDefinition"}
                          title={getLocalizedString("decimalFieldsDefinition")}
                          color={color}
                          fields={generalSettings.decimalFieldsDefinition}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.digitalSign ?
                        <Formulario
                          name={"digitalSign"}
                          title={getLocalizedString("digitalSign")}
                          color={color}
                          fields={generalSettings.digitalSign}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.timezones ?
                        <Formulario
                          name={"timezones"}
                          title={getLocalizedString("timezones")}
                          color={color}
                          fields={generalSettings.timezones}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.geographicOrganization ?
                        <Formulario
                          name={"geographicOrganization"}
                          title={getLocalizedString("geographicStructureSettings")}
                          color={color}
                          fields={generalSettings.geographicOrganization}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.logosAndCustomization ?
                        <>
                          <Formulario
                            name={"logosAndCustomization"}
                            title={getLocalizedString("logosAndCustomization")}
                            color={color}
                            fields={generalSettings.logosAndCustomization}
                            values={values}
                            handleTextChange={handleTextChange}
                            attachImage={attachImage}
                          />
                          <div style={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Button startIcon={<ColorizeIcon />} variant={"contained"} className="whiteText" onClick={() => setChangeColorDialogIsOpen(true)}>
                              {"Configurar color corporativo"}
                            </Button>
                          </div>
                        </>
                        : null
                    }
                    {
                      generalSettings?.permissions ?
                        <Formulario
                          name={"permissions"}
                          title={getLocalizedString("permissionsGeneralParameters")}
                          color={color}
                          fields={generalSettings.permissions}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.colaboratorPermissions ?
                        <Formulario
                          name={"colaboratorPermissions"}
                          title={getLocalizedString("colaboratorPermissions")}
                          color={color}
                          fields={generalSettings.colaboratorPermissions}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.peopleMaster ?
                        <Formulario
                          name={"peopleMaster"}
                          title={getLocalizedString("peopleMasterGeneralParameters")}
                          color={color}
                          fields={generalSettings.peopleMaster}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.hideFieldsPeopleMaster ?
                        <Formulario
                          name={"hideFieldsPeopleMaster"}
                          title={getLocalizedString("hideFieldsPeopleMasterGeneralParameters")}
                          color={color}
                          fields={generalSettings.hideFieldsPeopleMaster}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.historicalDataDuration ?
                        <Formulario
                          name={"historicalDataDuration"}
                          title={getLocalizedString("historicalDataDuration")}
                          color={color}
                          fields={generalSettings.historicalDataDuration}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.mobileApps ?
                        <Formulario
                          name={"mobileApps"}
                          title={getLocalizedString("mobileApps")}
                          color={color}
                          fields={generalSettings.mobileApps}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.calculationProcesses ?
                        <Formulario
                          name={"calculationProcesses"}
                          title={'De los procesos de cálculo de nómina'}
                          color={color}
                          fields={generalSettings.calculationProcesses}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.incidences ?
                        <Formulario
                          name={"incidences"}
                          title={'De las incidencias'}
                          color={color}
                          fields={generalSettings.incidences}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.orgchart ?
                        <Formulario
                          name={"orgchart"}
                          title={'Del organigrama'}
                          color={color}
                          fields={generalSettings.orgchart}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.observations ?
                        <Formulario
                          name={"observations"}
                          title={'De gestión de tiempos'}
                          subtitle={'Considera a las siguientes como "Observaciones" para el análisis de asistencia ?'}
                          color={color}
                          fields={generalSettings.observations}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.benefits ?
                        <Formulario
                          name={"benefits"}
                          title={'De los beneficios'}
                          color={color}
                          fields={generalSettings.benefits}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.publications ?
                        <Formulario
                          name={"publications"}
                          title={'De las publicaciones'}
                          color={color}
                          fields={generalSettings.publications}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.goalsAndObjectives ?
                        <Formulario
                          name={"goalsAndObjectives"}
                          title={getLocalizedString("goalsAndObjectives")}
                          color={color}
                          fields={generalSettings.goalsAndObjectives}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.performanceRatings ?
                        <Formulario
                          name={"performanceRatings"}
                          title={'De las calificaciones de desempeño'}
                          color={color}
                          fields={generalSettings.performanceRatings}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.talentRanking ?
                        <Formulario
                          name={"talentRanking"}
                          title={'De la clasificación del talento (9-Boxes)'}
                          color={color}
                          fields={generalSettings.talentRanking}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.talentRanking ?
                        <Formulario
                          name={"performanceAnalyitics"}
                          title={'De la analítica del desempeño y talento'}
                          color={color}
                          fields={generalSettings.performanceAnalyitics}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    {
                      generalSettings?.communicationChannelSettings ?
                        <Formulario
                          name={"communicationChannelSettings"}
                          title={'Del canal de comunicaciones'}
                          color={color}
                          fields={generalSettings.communicationChannelSettings}
                          values={values}
                          handleTextChange={handleTextChange}
                        />
                        : null
                    }
                    <Grid container item xs={12} justify={"flex-end"}>
                      <Button
                        className="whiteText"
                        style={{ cursor: "pointer" }}
                        type="submit"
                        variant="contained"
                        aria-label="save">
                        <SaveIcon htmlColor={color} />
                        <span style={{ marginLeft: 5 }}>{getLocalizedString("save")}</span>
                      </Button>
                    </Grid>
                  </form>
                );
              }}
            />
          </div>
      }
      {
        changeColorDialogIsOpen &&
        <ChangeColorDialog
          open={changeColorDialogIsOpen}
          companyColor={color}
          handleCloseDialog={() => setChangeColorDialogIsOpen(false)}
        />
      }
    </CommonPage>
  )
}
export default GeneralSettings;
