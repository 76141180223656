import NAME from "./constants";

export const GET_FUNCTIONS_SETTINGS = NAME + "/GET_FUNCTIONS_SETTINGS";
export const GET_FUNCTIONS_SETTINGS_FULFILLED = NAME + "/GET_FUNCTIONS_SETTINGS_FULFILLED";
export const GET_FUNCTIONS_SETTINGS_REJECTED = NAME + "/GET_FUNCTIONS_SETTINGS_REJECTED";

export const CREATE_FUNCTIONS_SETTINGS = NAME + "/CREATE_FUNCTIONS_SETTINGS";
export const CREATE_FUNCTIONS_SETTINGS_FULFILLED = NAME + "/CREATE_FUNCTIONS_SETTINGS_FULFILLED";
export const CREATE_FUNCTIONS_SETTINGS_REJECTED = NAME + "/CREATE_FUNCTIONS_SETTINGS_REJECTED";

export const UPDATE_FUNCTIONS_SETTINGS = NAME + "/UPDATE_FUNCTIONS_SETTINGS";
export const UPDATE_FUNCTIONS_SETTINGS_FULFILLED = NAME + "/UPDATE_FUNCTIONS_SETTINGS_FULFILLED";
export const UPDATE_FUNCTIONS_SETTINGS_REJECTED = NAME + "/UPDATE_FUNCTIONS_SETTINGS_REJECTED";

export const DELETE_FUNCTIONS_SETTINGS = NAME + "/DELETE_FUNCTIONS_SETTINGS";
export const DELETE_FUNCTIONS_SETTINGS_FULFILLED = NAME + "/DELETE_FUNCTIONS_SETTINGS_FULFILLED";
export const DELETE_FUNCTIONS_SETTINGS_REJECTED = NAME + "/DELETE_FUNCTIONS_SETTINGS_REJECTED";

export const RESET_STATE = NAME + "/RESET_STATE";

