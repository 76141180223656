
import React from "react";
import { TextField } from "mui-rff";
import { Typography, Grid } from "@material-ui/core";

const Number = ({ label, onChange, type, name, useWhiteText, required, disableInput, useMobileSize, audioPlayerIcon }) => {
    const gridSize = useMobileSize ? 12 : 6;

    return <Grid container item xs={12} alignItems="center" style={{ height: 64 }}>
        <Grid container direction="row" alignItems="center" wrap="nowrap" item xs={gridSize}>
            <Typography variant={"subtitle1"} className={"field"}>
                <span id={name} className={"editable-field-label" + useWhiteText && " whiteText"}>{label}:</span>
            </Typography>
            {
                audioPlayerIcon
            }
            {
                required &&
                <Typography variant={"subtitle1"} className={"errorColor field"} style={{ paddingLeft: 5 }}>
                    {"*"}
                </Typography>
            }
        </Grid>
        <Grid item xs={gridSize}>
            <TextField
                name={name}
                type={type}
                disabled={disableInput}
                margin={"none"}
                style={{ marginTop: 10 }}
                className={"custom-input-90-width-my-data"}
                onInput={e => {
                    const newValue = e.target.value.toString().slice(0, 3);
                    e.target.value = newValue;
                    onChange(newValue);
                }}
                onBlur={(e) => e.target.value = e.target.value.substring(0, 3)}
                inputProps={{ min: 0, max: 999, maxLength: 3 }}
            />
        </Grid>
    </Grid >
}

export default Number;
