import React from "react";
import { useSelector } from "react-redux";
import { Card, Typography, Grid, } from '@material-ui/core';
import { getLocalizedString } from "@icarius-localization/strings";
import DotsMenu from "@icarius-common/dotsMenu";
import MenuItem from '@material-ui/core/MenuItem';
import { LabelIcon } from "@icarius-icons/";
import { getAppColor, getUserData } from "src/app/selectors";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import paths from "@icarius-localization/paths";
import { useHistory } from "react-router-dom";
import UsersImageArray from "./usersImageArray";
import { getUsers } from "../selectors";
import TagList from "./tagList";

const Group = (props) => {

  const {
    tags,
    data,
    handleOpenEditGroupDialog,
    handleOpenDeleteGroupDialog,
    handleOpenLeaveGroupDialog,
    handleOpenMembersDialog,
    handleOpenMailDialog,
  } = props;

  const history = useHistory();

  const color = useSelector(getAppColor);
  const employees = useSelector(getUsers);
  const userData = useSelector(getUserData);

  const {
    img,
    participants,
    description,
    name,
    ownerName,
    owner,
    warehouse,
    ownerIsGone,
  } = data;

  const isOwner = userData.code === owner;
  const hasImage = Boolean(img);

  const handleOpenDigitalWarehouse = () => {
    history.push({
      pathname: paths.digitalWarehouse,
      search: `?code=${data.id}`,
      state: {
        name: name
      }
    });
  }

  return (
    <Card>
      <div
        style={{
          height: 120,
          backgroundImage: hasImage ? `url("${IMAGES_ENDPOINT + img}")` : "",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: !hasImage ? color : "",
        }}
      />
      <Grid container style={{ padding: "5px 20px" }}>
        <Grid container alignItems="center" item style={{ borderBottom: `1px solid ${color}` }}>
          <Grid item xs={11}>
            <Typography noWrap variant="h6" className="whiteText" style={{ fontWeight: 700, fontSize: 18 }}>
              {name}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <DotsMenu>
              <MenuItem onClick={() => handleOpenMailDialog(participants, name)}>
                {getLocalizedString("sendMessage")}
              </MenuItem>
              {
                (isOwner || ownerIsGone) &&
                <MenuItem onClick={() => handleOpenEditGroupDialog(data)}>
                  {getLocalizedString("edit")}
                </MenuItem>
              }
              {
                (isOwner || ownerIsGone) &&
                <MenuItem onClick={() => handleOpenDeleteGroupDialog(data)}>
                  {getLocalizedString("delete")}
                </MenuItem>
              }
              {
                (!isOwner || ownerIsGone) &&
                <MenuItem onClick={() => handleOpenLeaveGroupDialog(data)}>
                  {getLocalizedString("groupLeave")}
                </MenuItem>
              }
            </DotsMenu>
          </Grid>
        </Grid>
        <Grid container item xs={12} alignItems="center" style={{ paddingTop: 15, height: 70 }}>
          <LabelIcon style={{ fill: color, marginRight: 10 }} />
          <Grid item xs={10}>
            <Typography variant="h6" className="whiteText" style={{ fontWeight: 400, fontSize: 16 }}>
              {ownerName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 10, height: 60 }}>
          <Typography variant="h6" className="whiteText" style={{ fontWeight: 400, fontSize: 16 }}>
            {description}
          </Typography>
        </Grid>
        <Grid container item xs={12} alignItems="center" justify="space-between" style={{ paddingTop: 10 }}>
          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                onClick={() => handleOpenMembersDialog(data)}
                variant="h6"
                className="whiteText"
                style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}
              >
                {getLocalizedString("groupMembers")}
              </Typography>
              <Typography variant="h6" className="whiteText" style={{ fontWeight: 700, fontSize: 16, marginLeft: 20 }}>
                {participants.length}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <UsersImageArray
              users={participants}
              employees={employees}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} alignItems="center" style={{ padding: '20px 0px' }}>
          {
            warehouse ?
              <Typography
                onClick={handleOpenDigitalWarehouse}
                variant="h6"
                className="whiteText"
                style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}
              >
                {'Abrir bodega digital'}
              </Typography>
              :
              <Typography variant="h6" className="whiteText" style={{ fontWeight: 400, fontSize: 16 }}>
                {'No posee bodega digital'}
              </Typography>
          }
        </Grid>
        {
          Boolean(data.tags?.length) &&
          <Grid container item xs={12} alignItems="center" style={{ paddingBottom: 20, gap: 8 }}>
            <TagList
              tags={tags}
              values={data.tags}
            />
          </Grid>
        }
      </Grid>
    </Card>
  )
}

export default Group;