import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const SingleItemTable = (props) => {

    const {
        index,
        data,
        filters,
        title,
        overTitle,
        color,
        directions,
    } = props;

    let distance = "";
    let duration = "";

    if (directions?.length > 0) {
        distance = directions[index]["routes"][0]["legs"][0]["distance"]["text"];
        duration = directions[index]["routes"][0]["legs"][0]["duration"]["text"];
    }

    return (
        <Grid container>
            {
                overTitle &&
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        style={{ color: color }}
                    >
                        {distance !== "" && duration !== "" ? `${overTitle} - ${distance} - ${duration}` : overTitle}
                    </Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <Typography variant="h6">
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: "0px 6px 6px 0px" }} />
            {
                Object.keys(data).map((key, index) => {
                    return (
                        <Grid key={index} item container xs={12}>
                            <Typography style={{ marginRight: 5, fontWeight: "bold" }}>
                                {key + ":"}
                            </Typography>
                            <Typography>
                                {filters[key] === "Mon" ? formatNumberOrReturnUndefined(data[key], 2, 2) : data[key]}
                            </Typography>
                        </Grid>
                    )
                })
            }
        </Grid>
    );
}

export default SingleItemTable;