import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid, Typography } from "@material-ui/core";
import FabButton from "@icarius-common/fabButton";
import { NoGroupsSvg } from "@icarius-icons/";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const NoGroups = ({ onClick }) => {

    const color = useSelector(getAppColor);

    return (
        <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 50 }}>
            <div style={{ marginBottom: 30 }}>
                <NoGroupsSvg />
            </div>
            <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 18, fontWeight: 700, marginBottom: 16 }}>
                {getLocalizedString("noGroups")}
            </Typography>
            <Typography color="textSecondary" className="whiteText" variant="h3" style={{ fontSize: 16, fontWeight: 400, width: 170, textAlign: "center" }}>
                {getLocalizedString("groupsExplanation")}
            </Typography>
            <div style={{ marginTop: 30 }}>
                <FabButton
                    color={color}
                    ignoreIcon
                    useColorBackground
                    useWhiteTextAlways
                    onClick={() => onClick()}
                    title={getLocalizedString("groupsNewGroup")}
                />
            </div>
        </Grid>
    )
}

export default NoGroups;