import NAME from "./constants";

export const GET_QUERY_GROUPS = NAME + "/GET_QUERY_GROUPS";
export const GET_QUERY_GROUPS_FULFILLED = NAME + "/GET_QUERY_GROUPS_FULFILLED";
export const GET_QUERY_GROUPS_REJECTED = NAME + "/GET_QUERY_GROUPS_REJECTED";

export const GET_QUERIES = NAME + "/GET_QUERIES";
export const GET_QUERIES_FULFILLED = NAME + "/GET_QUERIES_FULFILLED";
export const GET_QUERIES_REJECTED = NAME + "/GET_QUERIES_REJECTED";

export const GET_QUERY_RESULT = NAME + "/GET_QUERY_RESULT";
export const GET_QUERY_RESULT_FULFILLED = NAME + "/GET_QUERY_RESULT_FULFILLED";
export const GET_QUERY_RESULT_REJECTED = NAME + "/GET_QUERY_RESULT_REJECTED";

export const GET_PEOPLE_ROWS = NAME + "/GET_PEOPLE_ROWS";
export const GET_PEOPLE_ROWS_FULFILLED = NAME + "/GET_PEOPLE_ROWS_FULFILLED";
export const GET_PEOPLE_ROWS_REJECTED = NAME + "/GET_PEOPLE_ROWS_REJECTED";

export const IMPORT_EXPORT = NAME + "/IMPORT_EXPORT";
export const IMPORT_EXPORT_FULFILLED = NAME + "/IMPORT_EXPORT_FULFILLED";
export const IMPORT_EXPORT_REJECTED = NAME + "/IMPORT_EXPORT_REJECTED";

export const RESET_EXTRA_DATA = NAME + "/RESET_EXTRA_DATA";

export const RESET_ROWS = NAME + "/RESET_ROWS";