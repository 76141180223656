import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, openSelectorValidationError) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "active") {
        return isCreate ? "Y" : data[fieldName];
      }

      if (fieldName === "showMarker") {
        return isCreate ? true : Boolean(data[fieldName] === "Y");
      }

      if (fieldName === "selectors") {
        return isCreate ? "" : data[fieldName].join(",");
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "name",
      "active",
      "sql",
      "rol",
      "group",
      "sequence",
      "selectors",
      "labelAbrev",
      "markScheme",
      "icon",
      "showMarker",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const codeRegex = /^[A-Z]+[A-Z0-9]*$/;

    const schema = yup.object().shape({
      code: yup.string().matches(codeRegex).max(20).required(),
      name: yup.string().max(254).required(),
      active: yup.string().required(),
      showMarker: yup.bool().required(),
      sql: yup.string().required(),
      rol: yup.string().required(),
      group: yup.string().max(100).required(),
      sequence: yup.string().max(3).required(),
      labelAbrev: yup.string().max(30).required(),
      markScheme: yup.string().required(),
      icon: yup.string().max(254).required(),
      selectors: yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const selectorsAreValid = () => {
    const selectors = formData.selectors.split(",");
    const validSelectors = [
      "Lista",
      "Fecha",
      "Alfa",
      "Num",
      "Mon",
      "ListaNone",
      "FechaNone",
      "AlfaNone",
      "NumNone",
      "MonNone",
    ];

    return selectors.every(selector => validSelectors.includes(selector));
  }

  const submit = async () => {
    if (await dataIsValid()) {
      if (selectorsAreValid()) {
        const formDataToSend = { ...formData };

        formDataToSend.showMarker = formDataToSend.showMarker ? "Y" : "N";
        formDataToSend.sequence = parseInt(formDataToSend.sequence);
        formDataToSend.selectors = formDataToSend.selectors.split(",");

        isCreate ? createCallback(formDataToSend) : modifyCallback(formDataToSend);
        return true;
      }
      openSelectorValidationError();
      return false;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    if (fieldName === "sequence") {
      return value.length > 0 ? value.replace(/\s/g, "") : "";
    }

    if (fieldName === "selectors") {
      if (value.length === 0) return "";
      if (value.charAt(0) === ",") {
        return value.substring(1);
      }
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
