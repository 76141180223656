import React, { useMemo } from "react";
import ProfileImg from "@icarius-common/profileImg";

const UsersImageArray = (props) => {

    const { users, employees } = props;
    const usersToShow = useMemo(() => users.length < 1 ? [] : users.length > 15 ? users.slice(0, 15) : users, [users]);

    return (
        <div style={{ display: "flex", height: 20, overflow: "hidden", marginTop: 10 }}>
            {
                usersToShow.map((user, index) => {
                    const employee = employees.find(employee => employee.key === user.key);
                    const profileImgToUse = Boolean(employee) ? employee["FOTOGRAFIA"] : "";
                    return (
                        <div key={index} style={{ position: "relative", left: `-${5 * index}px` }}>
                            <ProfileImg
                                image={profileImgToUse}
                                size={20}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default UsersImageArray;
