import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getFunctionsSettings,
  createFunctionsSettings,
  updateFunctionsSettings,
  deleteFunctionsSettings,
} from "@icarius-connection/api";

export const getFunctionsSettingsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_FUNCTIONS_SETTINGS });
  try {
    let response = await getFunctionsSettings();

    dispatch({
      type: actionTypes.GET_FUNCTIONS_SETTINGS_FULFILLED,
      payload: {
        rows: response.data.result,
      }
    });
    
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FUNCTIONS_SETTINGS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

//UPDATES
export const updateFunctionsSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_FUNCTIONS_SETTINGS });
  try {
    let response = await updateFunctionsSettings({ functionsSettings: data });
    
    dispatch({ type: actionTypes.UPDATE_FUNCTIONS_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("functionsUpdatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if(e.response.data.status === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("functionsDuplicatedData"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.UPDATE_FUNCTIONS_SETTINGS_REJECTED, payload: e });    
    return e.response;
  }
};

//CREATE
export const createFunctionsSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_FUNCTIONS_SETTINGS });
  try {
    let response = await createFunctionsSettings({ functionsSettings: data });
    
    dispatch({ type: actionTypes.CREATE_FUNCTIONS_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("functionsCreatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if(e.response.data.status === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("functionsDuplicatedData"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.CREATE_FUNCTIONS_SETTINGS_REJECTED, payload: e });
    return e.response;
  }
};

//DELETE
export const deleteFunctionsSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_FUNCTIONS_SETTINGS });
  try {
    let response = await deleteFunctionsSettings(data);
    
    dispatch({ type: actionTypes.DELETE_FUNCTIONS_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("functionsDeletedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if(e.response.data.status === "MANAGEMENT_CENTER_IN_USE"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("cantDeleteFunction"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));  
    }
    dispatch({ type: actionTypes.DELETE_FUNCTIONS_SETTINGS_REJECTED, payload: e });    
    return e.response;
  }
};
