import paths from "@icarius-localization/paths";
import {
	numberFormatterMin0Max3AllowsEmpty,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.generalValues],
        config: {
            headerName: "Código",
            field: "code",
            cellEditor: "asyncValidationCodeEditor",
            cellEditorParams: {
                condition: (value) => {
                    const codeRegex = /^[A-Z]+[A-Z0-9-]*$/;
                    return value && value.length < 12 && codeRegex.test(value);
                }
            },
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
            isObligatory: true,
            pattern: /^[A-Z]+[A-Z0-9-]*$/,
            maxLength: 12,
            minLength: 1
        }
    },
    {
        pages: [paths.generalValues],
        config: {
            headerName: "Nombre",
            field: "name",
            isObligatory: true,
            maxLength: 60,
            minLength: 1
        }
    },
    {
        pages: [paths.generalValues],
        config: {
            headerName: "Valor numérico",
            field: "num",
            filterParams: numberFilterParams,
            valueFormatter: numberFormatterMin0Max3AllowsEmpty,
            isNumeric: true,
            dataType: "number"
        }
    },
    {
        pages: [paths.generalValues],
        config: {
            headerName: "Valor alfanumérico",
            field: "alfa",
            isObligatory: false,
            minLength: 0,
            maxLength: 60
        }
    },
    {
        pages: [paths.generalValues],
        config: {
            headerName: "Valor fecha",
            field: "date",
            cellEditor: "dateEditor",
            filter: "agTextColumnFilter",
            isDate: true,
            dataType: "date"
        }
    },
]