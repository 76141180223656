import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  enabledCollaboratorActions: false,
  data: [],
  collaboratorList: [],
  statusList: [],
  periodList: [],
  taskList: [],
  targetTypeList: [],
  targetList: {},
  manager: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        collaboratorList: action.payload.collaboratorList,
        enabledCollaboratorActions: action.payload.enabledCollaboratorActions,
        periodList: action.payload.periodList,
        statusList: action.payload.statusList,
        targetTypeList: action.payload.targetTypeList,
        targetList: action.payload.targetList,
        manager: action.payload.manager,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.VERIFY:
      return { ...state, isLoading: true };
    case actionTypes.VERIFY_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.VERIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_TASKS:
      return { ...state, isLoading: true };
    case actionTypes.GET_TASKS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        taskList: action.payload.taskList,
      };
    case actionTypes.GET_TASKS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
