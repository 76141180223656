import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoadingMail } from "@icarius-pages/myPeople/selectors";
import { getGalleryImagesAction } from "@icarius-common/galleryDialog/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  deleteGroupAction,
  getGroupsDataAction,
  leaveGroupAction,
} from "../actions";
import { CLEAN_STATE } from "../actionTypes";
import {
  getGroups,
  getIsLoading,
  getTags,
  getUsers,
} from "../selectors";

const useMyGroups = () => {

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [formDialogIsOpen, setFormDialogIsOpen] = useState(false);
  const [membersDialogIsOpen, setMembersDialogIsOpen] = useState(false);

  const [mailDialogIsOpen, setMailDialogIsOpen] = useState(false);
  const [mailUsers, setMailUsers] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [tagFilter, setTagFilter] = useState('');

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const groups = useSelector(getGroups);
  const tags = useSelector(getTags);
  const employees = useSelector(getUsers);
  const isSendingMail = useSelector(getIsLoadingMail);

  useEffect(() => {
    dispatch(getGroupsDataAction())
      .then(() => dispatch(getGalleryImagesAction("groups")));

    return () => dispatch({ type: CLEAN_STATE });
  }, [dispatch])

  const handleOpenGroupDialog = (newSelectedGroup) => {
    setFormDialogIsOpen(true);
    setSelectedGroup(newSelectedGroup);
  }

  const handleCloseGroupDialog = () => {
    setFormDialogIsOpen(false);
    setSelectedGroup(null);
  }

  const handleOpenDeleteGroupDialog = (groupToDelete) => {
    dispatch(openDialogAction({
      title: 'Atención',
      msg: (
        <>
          {getLocalizedString("groupDeleteQuestion")}
          {
            groupToDelete.warehouse ?
              <>
                <br />
                El mismo contiene una bodega digital.
              </>
              : null
          }
        </>
      ),
      onConfirm: () => {
        dispatch(deleteGroupAction(groupToDelete.id))
          .then(() => dispatch(getGalleryImagesAction("groups")));
      },
    }));
  }

  const handleOpenLeaveDialog = (groupToLeave) => {
    dispatch(openDialogAction({
      title: 'Atención',
      msg: getLocalizedString("groupLeaveQuestion"),
      onConfirm: () => {
        dispatch(leaveGroupAction(groupToLeave.id))
          .then(() => dispatch(getGalleryImagesAction("groups")));
      },
    }));
  }

  const handleOpenMembersDialog = (newSelectedGroup) => {
    setMembersDialogIsOpen(true);
    setSelectedGroup(newSelectedGroup);
  }

  const handleCloseMembersDialog = () => {
    setMembersDialogIsOpen(false);
    setSelectedGroup(null);
  }

  const handleCloseMailDialog = () => {
    setMailDialogIsOpen(false);
    setMailUsers(null);
    setGroupName("");
  }

  const handleOpenMailDialog = (participants, newGroupName) => {
    const usersToUse = participants.map((user) => { //armar array de la forma { key, name, image }
      const employee = employees.find((employee) => employee.key === user.key);

      if (!employee) return null;
      return {
        key: user.key,
        image: employee.FOTOGRAFIA,
        name: user.name,
      }
    })

    setMailDialogIsOpen(true);
    setMailUsers(usersToUse);
    setGroupName(newGroupName);
  };

  const handleFilterByTag = (newTag) => {
    setTagFilter(newTag);
  }

  const handlers = {
    handleOpenGroupDialog,
    handleCloseGroupDialog,
    handleOpenDeleteGroupDialog,
    handleOpenLeaveDialog,
    handleOpenMembersDialog,
    handleCloseMembersDialog,
    handleOpenMailDialog,
    handleCloseMailDialog,
    handleFilterByTag,
  }

  const state = {
    groupName,
    selectedGroup,
    mailUsers,
    formDialogIsOpen,
    membersDialogIsOpen,
    mailDialogIsOpen,
    tagFilter,
  };

  return {
    isLoading,
    isSendingMail,
    groups,
    employees,
    tags,
    state,
    handlers,
  }
}

export default useMyGroups;