import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { AnnotationsPage } from "@icarius-pages/annotationsPage";
import FeedbackFormDialog from "@icarius-pages/feed/components/form/formDialog";
import { createFeedbackPublicationAction } from "@icarius-pages/feedback/actions";
import useGoalsAndObjectives from "./useGoalsAndObjectives";
import GraphsDialog from "./graphsDialog";
import CreateEditForm from "./createEditForm/index";
import FeedbackPublication from "./feedbackPublication";
import GridView from "./gridView";
import UserView from "./userView";

const GoalsAndObjectives = () => {

  const {
    isLoading,
    state,
    handlers,
    canOperate,
    level,
    data,
    taskList,
    manager,
    collaboratorList,
    periodList,
    statusList,
    targetTypeList,
    targetList,
    ownColumnDef,

    // feedback
    typeList,
    modeList,
    people,
    groups,
    tasks,
    goals,
    comments,
    isLoadingFeedback,
  } = useGoalsAndObjectives();

  const periodSelector = (
    <FormControl fullWidth style={{ width: "100%" }}>
      <InputLabel id={`label-type`}>{"Período"}</InputLabel>
      <Select
        value={state.selectedPeriod}
        labelId={`label-type`}
        id={`select-type`}
        onChange={(e) => handlers.handleChangePeriod(e.target.value)}
        margin={"none"}
      >
        {
          periodList?.map(item => (
            <MenuItem
              className={"whiteText"}
              key={item.key}
              value={item.key}>
              {item.value}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );

  const formIsOpen = (state.createDialogIsOpen || state.editDialogIsOpen);
  const showGridView = !formIsOpen && !Boolean(state.annotationsData) && state.gridIsOpen;
  const showUserView = !formIsOpen && !Boolean(state.annotationsData) && !state.gridIsOpen;

  const periodObject = periodList.find((item) => item.key === state.selectedPeriod);

  const isLoadingEverything = isLoading || isLoadingFeedback.gridData || isLoadingFeedback.privacyData || isLoadingFeedback.feed;

  return (
    <>
      {
        state.graphicsIsOpen &&
        <GraphsDialog
          open={state.graphicsIsOpen}
          data={data}
          periodName={periodObject?.value || ''}
          handleClose={handlers.handleCloseGraphics}
        />
      }
      {
        Boolean(state.annotationsData) &&
        <AnnotationsPage
          employee={state.annotationsData.employee}
          userDates={state.annotationsData.userDates}
          handleClose={handlers.handleCloseAnnotations}
        />
      }
      {
        formIsOpen &&
        <CreateEditForm
          readOnly={!canOperate}
          isLoading={isLoadingEverything}
          data={state.selectedRow}
          presetPeriod={state.selectedPeriod}
          statusList={statusList}
          taskList={taskList}
          collaboratorList={collaboratorList}
          periodList={periodList}
          targetTypeList={targetTypeList}
          targetList={targetList}
          handleOpenFeedbackForm={handlers.handleOpenFeedbackForm}
          handleOpenFeedbackPublication={handlers.handleOpenFeedbackPublication}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
      {
        Boolean(state.selectedFeedbackPublication) &&
        <FeedbackPublication
          isLoading={isLoadingFeedback}
          data={state.selectedFeedbackPublication}
          people={people}
          groups={groups}
          tasks={tasks}
          comments={comments}
          handleClose={handlers.handleCloseFeedbackPublication}
        />
      }
      {
        state.feedbackFormIsOpen &&
        <FeedbackFormDialog
          open={state.feedbackFormIsOpen}
          preselectedData={{
            goal: {
              key: state.selectedRow?.targetCode,
              endDate: state.selectedRow?.endDate,
              name: state.selectedRow?.targetName,
              progress: state.selectedRow?.progress,
            },
            userCodes: [state.selectedRow?.code]
          }}
          data={null}
          goals={goals}
          modeList={modeList}
          typeList={typeList}
          groups={groups}
          tasks={tasks}
          people={people}
          statusToCreate={'E'}
          createPublicationAction={(dataToSend) => createFeedbackPublicationAction(dataToSend, true)}
          isLoading={isLoadingEverything}
          handleClose={handlers.handleCloseFeedbackForm}
          isFeedback
          handleAfterCreateCallback={handlers.handleCreatePublication}
        />
      }
      {
        showGridView &&
        <GridView
          isLoading={isLoadingEverything}
          level={level}
          data={data}
          ownColumnDef={ownColumnDef}
          canOperate={canOperate}
          periodSelector={periodSelector}
          handlers={handlers}
        />
      }
      {
        showUserView &&
        <UserView
          key={periodObject?.key}
          isLoading={isLoadingEverything}
          data={data}
          periodObject={periodObject}
          periodSelector={periodSelector}
          level={level}
          canOperate={canOperate}
          people={collaboratorList}
          manager={manager}
          handlers={handlers}
        />
      }
    </>
  )
}

export default GoalsAndObjectives;