import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { PersonIcon } from "@icarius-icons";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Slider,
  Input,
  TextField,
  Chip,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";
import ProfileImg from "@icarius-common/profileImg";
import { getAppColor, getUserData } from "src/app/selectors";
import { withStyles } from "@material-ui/core/styles";
import {
  createGoalsAndObjectivesAction,
  getGoalsAndObjectivesTasksAction,
  modifyGoalsAndObjectivesAction,
} from "../../actions";
import useHandleForm from "./useHandleForm";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { yesNoArray } from "@icarius-utils/constants";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const CreateEditForm = (props) => {

  const {
    isLoading,
    readOnly,
    data,
    presetPeriod,
    taskList,
    statusList,
    collaboratorList,
    periodList,
    targetTypeList,
    targetList,
    handleOpenFeedbackForm,
    handleOpenFeedbackPublication,
    handleClose,
    classes,
  } = props;

  const [userSelectionIsOpen, setUserSelectionIsOpen] = useState(false);

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createGoalsAndObjectivesAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyGoalsAndObjectivesAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const fetchTasks = (dataToSend) => {
    dispatch(getGoalsAndObjectivesTasksAction(dataToSend));
  }

  const openValidationError = (hasEmployeeSelected) => {
    if (hasEmployeeSelected) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
      return;
    }

    dispatch(openSnackbarAction({ msg: 'Debe seleccionar un colaborador', severity: "error", duration: 10000 }));
  }

  const color = useSelector(getAppColor);
  const userData = useSelector(getUserData);

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, modify, fetchTasks, openValidationError, presetPeriod, userData, taskList, targetList);

  const handleInputChange = (event, fieldName) => {
    setFormValue(event.target.value === '' ? 0 : Number(event.target.value), fieldName);
  };

  const handleInputBlur = (value, fieldName) => {
    if (value < 0) {
      setFormValue(0, fieldName);
    } else if (value > 100) {
      setFormValue(100, fieldName);
    }
  };

  const employeeSelected = collaboratorList.find((item) => item.key === formData.code);
  const periodSelected = periodList.find((item) => item.key === formData.period);
  const minDate = periodSelected?.from ? createDateFromDDMMYYYY(periodSelected.from) : null;
  const maxDate = periodSelected?.to ? createDateFromDDMMYYYY(periodSelected.to) : null;

  const filteredTargetList = formData.targetType ? targetList[formData.targetType] : [];

  const isCollabTaskMadeByOther = !isCreate && userData.level === 'C' && data.assigner !== userData.code;

  return (
    <CommonPage
      title={isCreate ? "Crear objetivo" : "Editar objetivo"}
      isLoading={isLoading}
      isNotGridPage
    >
      <Grid container item xs={12}>
        <Grid container style={{ margin: '0px 24px' }}>
          <Grid container item xs={12} sm={11} style={{ paddingLeft: 75 }}>
            {
              isCreate && userData.level !== 'C' &&
              <Grid container item alignItems="center" justify="center" xs={12}>
                <Button
                  variant='contained'
                  className='whiteText'
                  onClick={() => setUserSelectionIsOpen(true)}
                  startIcon={<PersonIcon htmlColor={color} />}
                >
                  Seleccionar colaborador
                </Button>
              </Grid>
            }
            {
              Boolean(employeeSelected) &&
              <Grid container xs={12} item alignItems="center" justify="center">
                <ProfileImg
                  image={employeeSelected.image}
                  size={40}
                />
                <Typography variant="h6" className="whiteText" style={{ marginLeft: 10, fontWeight: 100, fontSize: 16 }}>
                  {employeeSelected.value}
                </Typography>
              </Grid>
            }
          </Grid>
          <Grid container item xs={12} sm={1} justify="flex-end">
            <CustomIconButton
              title={getLocalizedString("goBack")}
              onClick={handleClose}
              type={"goBack"}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-targetType`}>{"Tipo de objetivo"}</InputLabel>
            <Select
              disabled={isCollabTaskMadeByOther || readOnly}
              value={formData.targetType}
              labelId={`label-targetType`}
              id={`select-targetType`}
              onChange={(e) => setFormValue(e.target.value, "targetType")}
              margin={"none"}
            >
              {
                targetTypeList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-target`}>{"Objetivo"}</InputLabel>
            <Select
              disabled={isCollabTaskMadeByOther || readOnly}
              value={formData.target}
              labelId={`label-target`}
              id={`select-target`}
              onChange={(e) => setFormValue(e.target.value, "target")}
              margin={"none"}
            >
              {
                filteredTargetList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <TextField
            disabled={isCollabTaskMadeByOther || readOnly}
            required
            fullWidth
            margin={"none"}
            label={'Nombre de objetivo'}
            value={formData.targetName}
            onChange={(e) => setFormValue(e.target.value, "targetName")}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-period`}>{"Período"}</InputLabel>
            <Select
              disabled // siempre es disabled
              value={formData.period}
              labelId={`label-period`}
              id={`select-period`}
              onChange={(e) => setFormValue(e.target.value, "period")}
              margin={"none"}
            >
              {
                periodList?.filter((item) => item.status !== 'C')?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-status`}>{"Estado"}</InputLabel>
            <Select
              disabled={isCollabTaskMadeByOther || readOnly}
              value={formData.status}
              labelId={`label-status`}
              id={`select-status`}
              onChange={(e) => setFormValue(e.target.value, "status")}
              margin={"none"}
            >
              {
                statusList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={{ ...gridStyle, marginTop: -7 }}>
          <Grid container item xs={12}>
            <Typography className="whiteText" style={{ fontSize: 12 }}>Ponderado del objetivo</Typography>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={2} style={{ paddingRight: 10 }}>
              <Input
                fullWidth
                disabled={isCollabTaskMadeByOther || ['D', 'P'].includes(formData.status) || readOnly}
                value={formData.targetWeighting}
                size="small"
                onChange={(e) => handleInputChange(e, "targetWeighting")}
                onBlur={() => handleInputBlur(formData.targetWeighting, "targetWeighting")}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 0.5,
                  type: 'number',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Slider
                disabled={isCollabTaskMadeByOther || ['D', 'P'].includes(formData.status) || readOnly}
                onChange={(_, value) => setFormValue(value, "targetWeighting")}
                min={0}
                max={100}
                step={1}
                defaultValue={0}
                value={formData.targetWeighting}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              disabled={isCollabTaskMadeByOther || readOnly}
              fullWidth
              required
              label={'Fecha de asignación'}
              cancelLabel={getLocalizedString("cancel")}
              okLabel={getLocalizedString("ok")}
              invalidDateMessage=''
              minDateMessage=''
              format="dd/MM/yyyy"
              margin="normal"
              minDate={minDate}
              maxDate={maxDate}
              value={formData.startDate}
              onChange={(e) => setFormValue(e, "startDate")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              disabled={isCollabTaskMadeByOther || readOnly}
              fullWidth
              required
              label={'Fecha de término'}
              cancelLabel={getLocalizedString("cancel")}
              okLabel={getLocalizedString("ok")}
              invalidDateMessage=''
              minDateMessage=''
              maxDateMessage=''
              format="dd/MM/yyyy"
              margin="normal"
              minDate={formData.startDate}
              value={formData.endDate}
              onChange={(e) => setFormValue(e, "endDate")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              disabled={isCollabTaskMadeByOther || readOnly}
              fullWidth
              label={'Fecha de revisión'}
              cancelLabel={getLocalizedString("cancel")}
              okLabel={getLocalizedString("ok")}
              invalidDateMessage=''
              minDateMessage=''
              maxDateMessage=''
              format="dd/MM/yyyy"
              margin="normal"
              minDate={formData.startDate}
              value={formData.revisionDate}
              onChange={(e) => setFormValue(e, "revisionDate")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: 10 }}>
          <TextField
            disabled={isCollabTaskMadeByOther || readOnly}
            fullWidth
            label="Descripción"
            variant="outlined"
            multiline
            rows={1}
            margin={"none"}
            value={formData.targetDescription}
            onChange={(e) => setFormValue(e.target.value, "targetDescription")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={4} style={{ ...gridStyle, marginTop: 4 }}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink id={`label-relatedTask`}>{"Tarea relacionada"}</InputLabel>
            <Select
              displayEmpty
              disabled={isCollabTaskMadeByOther || !Boolean(formData.code) || readOnly}
              value={formData.relatedTask}
              labelId={`label-relatedTask`}
              id={`select-relatedTask`}
              onChange={(e) => setFormValue(e.target.value, "relatedTask")}
              margin={"none"}
            >
              <MenuItem className={"whiteText"} value={''}>
                {'Ninguna'}
              </MenuItem>
              {
                taskList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              disabled={isCollabTaskMadeByOther || Boolean(formData.relatedTask) || readOnly}
              fullWidth
              required
              label={'Fecha de progreso'}
              cancelLabel={getLocalizedString("cancel")}
              okLabel={getLocalizedString("ok")}
              invalidDateMessage=''
              minDateMessage=''
              format="dd/MM/yyyy"
              margin="normal"
              minDate={minDate}
              value={formData.progressDate}
              onChange={(e) => setFormValue(e, "progressDate")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={{ ...gridStyle, marginTop: -2 }}>
          <Grid container item xs={12}>
            <Typography className="whiteText" style={{ fontSize: 12 }}>Porcentaje de avance de progreso</Typography>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={2} style={{ paddingRight: 10 }}>
              <Input
                disabled={isCollabTaskMadeByOther || Boolean(formData.relatedTask) || readOnly}
                fullWidth
                value={formData.progress}
                size="small"
                onChange={(e) => handleInputChange(e, "progress")}
                onBlur={() => handleInputBlur(formData.progress, "progress")}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 0.5,
                  type: 'number',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Slider
                disabled={isCollabTaskMadeByOther || Boolean(formData.relatedTask) || readOnly}
                onChange={(_, value) => setFormValue(value, "progress")}
                min={0}
                max={100}
                step={1}
                defaultValue={0}
                value={formData.progress}
              />
            </Grid>
          </Grid>
        </Grid>
        {
          ['M'].includes(userData.level) && isCreate &&
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-hasCollaboratorApproval`}>{"¿Debe aprobarlo el colaborador?"}</InputLabel>
              <Select
                disabled={readOnly}
                value={formData.hasCollaboratorApproval}
                labelId={`label-hasCollaboratorApproval`}
                id={`select-hasCollaboratorApproval`}
                onChange={(e) => setFormValue(e.target.value, "hasCollaboratorApproval")}
                margin={"none"}
              >
                {
                  yesNoArray?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        }
        {
          ['C'].includes(userData.level) && isCreate &&
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-hasManagerApproval`}>{"¿Debe aprobarlo el manager?"}</InputLabel>
              <Select
                disabled // va disabled siempre
                value={formData.hasManagerApproval}
                labelId={`label-hasManagerApproval`}
                id={`select-hasManagerApproval`}
                onChange={(e) => setFormValue(e.target.value, "hasManagerApproval")}
                margin={"none"}
              >
                {
                  yesNoArray?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        }
        {
          !isCreate && data.visibleApprovedByCollaborator &&
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-approvedByCollaborator`}>
                {
                  data.editableApprovedByCollaborator
                    ? "¿Apruebas el objetivo?"
                    : "¿Aprobado por el colaborador?"
                }
              </InputLabel>
              <Select
                disabled={!data.editableApprovedByCollaborator}
                value={formData.approvedByCollaborator}
                labelId={`label-approvedByCollaborator`}
                id={`select-approvedByCollaborator`}
                onChange={(e) => setFormValue(e.target.value, "approvedByCollaborator")}
                margin={"none"}
              >
                {
                  yesNoArray?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        }
        {
          !isCreate && data.visibleApprovedByManager &&
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-approvedByManager`}>
                {
                  data.editableApprovedByCollaborator
                    ? "¿Apruebas el objetivo?"
                    : "¿Aprobado por el manager?"
                }
              </InputLabel>
              <Select
                disabled={!data.editableApprovedByManager}
                value={formData.approvedByManager}
                labelId={`label-approvedByManager`}
                id={`select-approvedByManager`}
                onChange={(e) => setFormValue(e.target.value, "approvedByManager")}
                margin={"none"}
              >
                {
                  yesNoArray?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        }
      </Grid>
      {
        <Grid container item xs={12} style={{ paddingBottom: 20 }}>
          <Grid container item direction="column" sm={12} md={6}>
            {
              <>
                {
                  !readOnly && !isCreate && ['M', 'E'].includes(userData.level) &&
                  <div>
                    <ButtonDialogAction isAccept onClick={() => handleOpenFeedbackForm(isCreate, formData)} text={'Dar feedback'} />
                  </div>
                }
                {
                  Boolean(data?.feedbacks?.length) &&
                  <Typography className="whiteText" style={{ paddingTop: 20 }}>Feedbacks:</Typography>
                }
                <div style={{ display: 'flex', flexDirection: 'column', gap: 5, paddingBottom: 20 }}>
                  {
                    (data?.feedbacks || []).map((item, index) => {
                      return (
                        <Chip
                          key={index}
                          label={item.reference}
                          onClick={() => handleOpenFeedbackPublication(item)}
                        />
                      )
                    })
                  }
                </div>
              </>
            }
          </Grid>
          {
            !readOnly &&
            <Grid container item direction="column" sm={12} md={6}>
              <Grid container item xs={12} justify={"flex-end"} alignItems="flex-start" style={{ paddingBottom: 20 }}>
                <div style={{ marginRight: 10 }}>
                  <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
                </div>
                <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
              </Grid>
            </Grid>
          }
        </Grid>
      }
      {
        userSelectionIsOpen &&
        <UserSelectionDialog
          open={userSelectionIsOpen}
          initialData={[formData.code]}
          users={collaboratorList.map((item) => ({ ...item, name: item.value }))}
          title={'Seleccionar colaboradores'}
          selectedUsersTitle={'Seleccionados'}
          handleSubmit={(data) => setFormValue(Boolean(data.length) ? data[0] : null, "code")}
          handleClose={() => setUserSelectionIsOpen(false)}
          singleSelection
        />
      }
    </CommonPage>
  );
}

export default withStyles(styles)(CreateEditForm);