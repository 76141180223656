import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.geoQuery],
        config: {
            headerName: "Grupo de ubicación",
            field: "group",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.geoQuery],
        config: {
            headerName: "Secuencia de aparición del grupo",
            field: "sequence",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.geoQuery],
        config: {
            headerName: "Activa",
            field: "activeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.geoQuery],
        config: {
            headerName: "Código de consulta",
            field: "code",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.geoQuery],
        config: {
            headerName: "Nombre",
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.geoQuery],
        config: {
            headerName: "Rol de visualización",
            field: "rolString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.geoQuery],
        config: {
            headerName: "Esquema de marcadores",
            field: "markSchemeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.geoQuery],
        config: {
            headerName: "Icono para marcador",
            field: "icon",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.geoQuery],
        config: {
            headerName: "Abreviatura para etiqueta",
            field: "labelAbrev",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.geoQuery],
        config: {
            headerName: "Mostrar nombre en el marcador",
            field: "showMarkerString",
            filter: "agTextColumnFilter",
        }
    },
]