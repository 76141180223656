import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading;
export const getData = store => store[NAME].data;
export const getCollaboratorList = store => store[NAME].collaboratorList;
export const getEnabledCollaboratorActions = store => store[NAME].enabledCollaboratorActions;
export const getPeriodList = store => store[NAME].periodList;
export const getStatusList = store => store[NAME].statusList;
export const getTargetTypeList = store => store[NAME].targetTypeList;
export const getTargetList = store => store[NAME].targetList;
export const getTaskList = store => store[NAME].taskList;
export const getManager = store => store[NAME].manager;