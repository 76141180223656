import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import {
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import {
  getGeoanalysisGroupsFromServer,
  executeGeoanalysisQueryFromServer,
  exportQuery,
  importCustomQuery
} from "../actions";
import {
  getFilters,
  getDateFormat,
  getQueryResult,
  getLocale,
  getNMark,
  getSelectableColumns
} from "../selectors";
import { RoomIcon } from "@icarius-icons";
import {
  createAggridColumn,
} from "@icarius-table/utils";
import CommonPage from "@icarius-common/commonPage";
import SetParamDialog from "@icarius-common/setParamDialog";
import MyMapComponent from "./map/myMapComponent";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { RESET_EXTRA_DATA, RESET_ROWS } from "../actionTypes";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { FavouriteButton } from "@icarius-common/favouriteButton";
import { getAppColor, getUserData } from "src/app/selectors";
import { customFileTypes } from "@icarius-utils/constants";

class GridScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapIsOpen: false,
      queryCodeSelected: "",
      gridDef: [],
      setParamDialogIsOpen: false,
      currentParam: null,
      queryParams: null,
      mapData: [],
      columnSelected: "",
      alreadyPresetQuery: false,
    };
    this.currentChartRef = null;
  }

  componentWillUnmount() {
    this.props.dispatch({ type: RESET_EXTRA_DATA });
  }

  componentDidUpdate() {
    const { queries, queryPreset, userPreset } = this.props;
    if (!this.state.alreadyPresetQuery) {
      if (queryPreset) {
        const query = queries.find(o => o.code === queryPreset);
        if (query) {
          this.setState({
            queryCodeSelected: queryPreset,
            columnSelected: "",
            alreadyPresetQuery: true
          });

          if (query.parameters !== undefined) {
            this.handleOpenParamDialog(query.parameters);
          } else {
            //llamado a la api de ejecutar consulta
            this.props.dispatch(executeGeoanalysisQueryFromServer({ code: queryPreset, employeeCode: userPreset }))
              .then(() => this.setState({ gridDef: this.createColumnDefs() }));
          }
        }
      }
    }
  }

  createColumnDefs = () => {
    let columnDefs = [];
    for (let prop in this.props.filters) {
      if (Object.prototype.hasOwnProperty.call(this.props.filters, prop)) {
        let item = createAggridColumn(prop, this.props.filters[prop]);
        columnDefs.push(item);
      }
    }
    return columnDefs;
  }

  handleCloseParamDialog = () => { this.setState({ setParamDialogIsOpen: false, currentParam: null }) }

  handleOpenParamDialog = (params) => { this.setState({ setParamDialogIsOpen: true, currentParam: params }) }

  resetGrid = (gridRef) => {
    gridRef.columnApi.resetColumnState();
    gridRef.columnApi.resetColumnGroupState();
    gridRef.columnApi.setPivotMode(false);
    gridRef.api.setFilterModel(null);
    gridRef.api.onFilterChanged();
    gridRef.api.onSortChanged();
    if (this.currentChartRef) {
      this.currentChartRef.destroyChart();
      this.currentChartRef = null;
    }
  }

  handleSelectQueryWithParams = (params, gridRef) => {
    const query = { code: this.state.queryCodeSelected, parameters: params, employeeCode: this.props.userPreset }

    this.props.dispatch({ type: RESET_EXTRA_DATA });

    this.props.dispatch(executeGeoanalysisQueryFromServer(query))
      .then(() => this.setState(
        {
          gridDef: this.createColumnDefs(),
          queryParams: params,
          columnSelected: this.props.selectableColumns.length > 0 ? this.props.selectableColumns[0] : ""
        }
      ));

    gridRef.api.clearRangeSelection();
    gridRef.api.deselectAll();
  }

  handleSelectQuery = (e, queries, gridRef) => {
    if (e.target.value !== this.state.queryCodeSelected) {

      // Vacio la grilla
      this.props.dispatch({ type: RESET_ROWS });
      this.props.dispatch({ type: RESET_EXTRA_DATA });

      this.resetGrid(gridRef);
      this.setState({ queryParams: null, mapData: [], mapIsOpen: false });

      const query = queries.find(o => o.code === e.target.value);

      if (query) {
        this.setState({ queryCodeSelected: e.target.value });

        if (query.parameters !== undefined) {
          this.handleOpenParamDialog(query.parameters);
        }
        else {

          this.props.dispatch(executeGeoanalysisQueryFromServer({ code: e.target.value, employeeCode: this.props.userPreset }))
            .then(() =>
              this.setState({
                gridDef: this.createColumnDefs(),
                columnSelected: this.props.selectableColumns.length > 0 ? this.props.selectableColumns[0] : ""
              })
            );
        }
      }
    }
    gridRef.api.clearRangeSelection();
    gridRef.api.deselectAll();
  }

  getQueryName = (code) => {
    if (!this.props.queries) return "";
    const query = this.props.queries.find(query => query.code === code);
    return query ? query.name : "";
  }

  handleExportQuery = () => {
    const { queryCodeSelected } = this.state;
    const exportObject = { code: queryCodeSelected, name: this.getQueryName(queryCodeSelected) };
    // Llamo al backend para que me pase toda la data

    // Ahora que ya tengo esto, genero el archivo
    this.props.dispatch(exportQuery(exportObject)).then(resp => {
      try {
        if (resp.data.status === "OK") {
          const data = resp.data.result;

          const encryptedObject = this.utf8_to_b64(JSON.stringify(data, undefined, 1));

          let element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encryptedObject);
          element.setAttribute('download', `${queryCodeSelected}.${customFileTypes.icarius}`);

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        } else {
          this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
        }
      } catch (e) {
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      }
    });
  }

  utf8_to_b64 = (str) => {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  handleImportQuery = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = `.${customFileTypes.icarius}`;
    input.onchange = e => {

      // getting a hold of the file reference
      let file = e.target.files[0];

      // setting up the reader
      let reader = new FileReader();
      reader.readAsText(file); // this is reading as data url
      // here we tell the reader what to do when it's done reading...
      reader.onload = event => {
        try {
          const utf8Object = this.b64_to_utf8(event.target.result);
          const parsedObject = JSON.parse(utf8Object);
          if (parsedObject.query && parsedObject.parameters) {
            this.uploadTemplateToServer(parsedObject);
          } else {
            this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("incorrectQuery"), severity: "error" }));
          }
        } catch (e) {
          this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("incorrectQuery"), severity: "error" }));
        }
      }
    }
    input.click();
  }

  b64_to_utf8 = (str) => {
    return decodeURIComponent(escape(window.atob(str)));
  }

  uploadTemplateToServer = (parsedObject) => {
    this.props.dispatch(importCustomQuery(parsedObject)).then(response => {
      if (response.data.status === "OK") {
        this.props.dispatch(getGeoanalysisGroupsFromServer());
        this.props.handleCloseGrid();
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedString("queryImport"), severity: "success" }));
      } else {
        this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      }
    }).catch(() => {
      this.props.dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
    });
  }

  handleOpenMap = (gridRef) => {
    let mapDataToUse = [];

    if (!this.state.mapIsOpen) {
      gridRef.api.forEachNodeAfterFilter((row) => {
        if (row.data) {
          mapDataToUse.push(row.data);
        }
      })
    }

    this.setState({ mapIsOpen: !this.state.mapIsOpen, mapData: mapDataToUse });
  }

  render() {
    const {
      title,
      color,
      queries,
      handleCloseGrid,
      peopleRows,
      dateFormat,
      locale,
      filters,
      selectableColumns,
      nmark,
      favouriteList,
      handleChangeFavourite,
      queryPreset,
      userData,
      isFromHiddenGroup,
    } = this.props;

    const shouldHideButtons = isFromHiddenGroup || (queryPreset && userData.level !== 'E');

    const CustomSelect = withStyles({
      select: {
        width: 370,
      },
    })(Select);

    const goBackItem = () => !shouldHideButtons && (
      <CustomIconButton
        title={getLocalizedString("goBack")}
        onClick={handleCloseGrid}
        type={"goBack"}
      />
    )

    const favItem = () => {
      const isFavourite = favouriteList?.includes(this.state.queryCodeSelected) || false;

      if (shouldHideButtons || !this.state.queryCodeSelected) return null;

      return (
        <FavouriteButton
          type={"GEO"}
          code={this.state.queryCodeSelected}
          isFavourite={isFavourite}
          handleChangeFavourite={handleChangeFavourite}
        />
      )
    }

    const mapItem = (gridRef) => peopleRows.length > 0 &&
      <CustomIconButton
        title={getLocalizedString(this.state.mapIsOpen ? "closeMap" : "openMap")}
        onClick={() => this.handleOpenMap(gridRef)}
      >
        <RoomIcon />
      </CustomIconButton>

    const exporQueryItem = () => peopleRows.length > 0 && !shouldHideButtons &&
      <CustomIconButton
        title={getLocalizedString("exportQuery")}
        onClick={this.handleExportQuery}
        type={"download"}
      />

    const importQueryItem = () => peopleRows.length > 0 && !shouldHideButtons &&
      <CustomIconButton
        title={getLocalizedString("importQuery")}
        onClick={this.handleImportQuery}
        type={"upload"}
      />

    const selectQueriesAndColumn = (gridRef) =>
      <>
        <div>
          <FormControl style={{ maxWidth: 350 }}>
            <CustomSelect
              value={this.state.queryCodeSelected}
              onChange={e => this.handleSelectQuery(e, queries, gridRef)}
              displayEmpty>
              <MenuItem value="" disabled>{getLocalizedString("selectQuery")}</MenuItem>
              {
                queries.map((query, index) =>
                  <MenuItem key={index} value={query.code}>{query.name}</MenuItem>
                )
              }
            </CustomSelect>
          </FormControl>
        </div>
        {
          nmark === "Y" &&
          <div>
            <FormControl style={{ marginLeft: 10, maxWidth: 350 }}>
              <CustomSelect
                value={this.state.columnSelected}
                onChange={e => this.setState({ columnSelected: e.target.value })}
                displayEmpty>
                <MenuItem value="" disabled>{getLocalizedString("selectColumn")}</MenuItem>
                {
                  selectableColumns.map((column, index) =>
                    <MenuItem key={index} value={column}>{column}</MenuItem>
                  )
                }
              </CustomSelect>
            </FormControl>
          </div>
        }
      </>

    const hijo = (gridRef) =>
      <>
        {
          this.state.setParamDialogIsOpen && this.state.currentParam &&
          <SetParamDialog
            open={this.state.setParamDialogIsOpen}
            params={this.state.currentParam}
            handleClose={this.handleCloseParamDialog}
            handleAgree={(params) => this.handleSelectQueryWithParams(params, gridRef)}
          />
        }
        {
          this.state.mapIsOpen &&
          <MyMapComponent
            data={this.state.mapData}
            filters={filters}
            color={color}
            nmark={nmark}
            columnSelected={this.state.columnSelected}
            isMarkerShown />
        }
      </>

    return (
      <CommonPage
        hideGrid={this.state.mapIsOpen}
        title={`${getLocalizedString("geoanalysisTitle")} - ${title}`}
        gridTitle={title}
        locale={locale}
        dateFormat={dateFormat}
        rowData={peopleRows}
        menuItems={[goBackItem, favItem, mapItem, exporQueryItem, importQueryItem]}
        ownColumnDef={this.state.gridDef}
        customHeader={selectQueriesAndColumn}
        hasHelp
        hasSelectAll
        hasExpand
      >
        {hijo}
      </CommonPage>
    );
  }
}

export default connect(
  createStructuredSelector({
    filters: getFilters,
    peopleRows: getQueryResult,
    dateFormat: getDateFormat,
    locale: getLocale,
    selectableColumns: getSelectableColumns,
    nmark: getNMark,
    userData: getUserData,
    color: getAppColor,
  })
)(GridScreen);
