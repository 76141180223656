import NAME from "./constants";

export const GET_GEOGRAPHICAL_STRUCTURE_DATA = NAME + "/GET_GEOGRAPHICAL_STRUCTURE_DATA";
export const GET_GEOGRAPHICAL_STRUCTURE_DATA_FULFILLED = NAME + "/GET_GEOGRAPHICAL_STRUCTURE_DATA_FULFILLED";
export const GET_GEOGRAPHICAL_STRUCTURE_DATA_REJECTED = NAME + "/GET_GEOGRAPHICAL_STRUCTURE_DATA_REJECTED";
export const GET_GEOGRAPHICAL_OCCUPATION_DATA = NAME + "/GET_GEOGRAPHICAL_OCCUPATION_DATA";
export const GET_GEOGRAPHICAL_OCCUPATION_DATA_FULFILLED = NAME + "/GET_GEOGRAPHICAL_OCCUPATION_DATA_FULFILLED";
export const GET_GEOGRAPHICAL_OCCUPATION_DATA_REJECTED = NAME + "/GET_GEOGRAPHICAL_OCCUPATION_DATA_REJECTED";
export const SAVE_GEOGRAPHICAL_STRUCTURE_DATA = NAME + "/SAVE_GEOGRAPHICAL_STRUCTURE_DATA";
export const SAVE_GEOGRAPHICAL_STRUCTURE_DATA_FULFILLED = NAME + "/SAVE_GEOGRAPHICAL_STRUCTURE_DATA_FULFILLED";
export const SAVE_GEOGRAPHICAL_STRUCTURE_DATA_REJECTED = NAME + "/SAVE_GEOGRAPHICAL_STRUCTURE_DATA_REJECTED";