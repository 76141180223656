import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createGroupAction, editGroupAction } from "@icarius-pages/groups/actions";
import { getGalleryImagesAction, uploadImageAction, deleteImageAction } from "@icarius-common/galleryDialog/actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import GalleryDialog from "@icarius-common/galleryDialog/components/index";
import { getGalleryImages } from "@icarius-common/galleryDialog/selectors"
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import DialogTransition from "@icarius-common/dialogTransition";
import useSetImageIndex from "@icarius-common/galleryDialog/components/useSetImageIndex";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";
import UsersImageArray from "../../usersImageArray";
import useHandleForm from "./useHandleForm";
import { getUserData } from "src/app/selectors";
import TagsInput from "./tagsInput";
import TagList from "../../tagList";

const FormDialog = (props) => {

  const {
    open,
    data,
    employees,
    tags,
    handleClose,
  } = props;

  const dispatch = useDispatch();
  const images = useSelector(getGalleryImages);
  const userData = useSelector(getUserData);

  const [userSelectionDialogIsOpen, setUserSelectionDialogIsOpen] = useState(false);
  const [imagesDialogIsOpen, setImagesDialogIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useSetImageIndex(data, images, "img");

  const create = (dataToSend) => {
    dispatch(createGroupAction(dataToSend))
      .then(() => {
        dispatch(getGalleryImagesAction("groups"));
        handleClose();
      });
  }

  const modify = (dataToSend) => {
    dispatch(editGroupAction({
      ...dataToSend,
      id: data.id,
    }))
      .then(() => {
        dispatch(getGalleryImagesAction("groups"));
        handleClose();
      });
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openValidationUsersError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("groupAtLeastOneUser"), severity: "error" }));
  }

  const uploadImage = (file) => {
    setImageIndex(null);
    dispatch(uploadImageAction(file, "groups"));
  }

  const deleteImage = (filename) => {
    setImageIndex(null);
    dispatch(deleteImageAction(filename, "groups"));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError, openValidationUsersError, userData.code);

  const handleSelectImage = (index) => {
    setFormValue(index !== null ? images[index].img : "", 'img');
    setImageIndex(index);
  }

  const participantsObjects = employees.filter((item) => formData.participants.includes(item.key));

  const handleDeleteTag = (tagKey) => {
    const filteredTags = formData.tags.filter((item) => item !== tagKey);
    setFormValue(filteredTags, 'tags');
  }

  return (
    <>
      <Dialog
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        open={open}
        fullWidth
        maxWidth={"sm"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString(!isCreate ? "groupEditTitle" : "groupCreateTitle")}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container alignItems="center">
            {
              imageIndex !== null &&
              <Grid item xs={12} sm={6}>
                <img
                  style={{ width: 220, height: 140 }}
                  src={images && images.length > 0 ? IMAGES_ENDPOINT + images[imageIndex].img : ""}
                  alt={"imageNotFound"}
                />
              </Grid>
            }
            <Grid item xs={12} sm={6} onClick={() => setImagesDialogIsOpen(true)}>
              <Typography variant="h6" className="whiteText" style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}>
                {getLocalizedString("groupImagesTitle")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="flex-start" alignItems="center" style={{ paddingTop: 20 }}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={getLocalizedString("groupName")}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, 'name')}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid container justify="flex-start" alignItems="center" style={{ paddingTop: 20 }}>
            <TextField
              fullWidth
              margin={"none"}
              label={getLocalizedString("groupDescription")}
              value={formData.description}
              onChange={(e) => setFormValue(e.target.value, 'description')}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>
          <Grid container justify="flex-start" alignItems="center" style={{ paddingTop: 20 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.warehouse}
                  value={formData.warehouse}
                  onChange={() => setFormValue(!formData.warehouse, 'warehouse')}
                />
              }
              label={'Contiene bodega digital'}
              labelPlacement="start"
              style={{ marginLeft: 0 }}
            />
          </Grid>
          <Grid container justify="flex-start" alignItems="center" style={{ paddingTop: 20 }} onClick={() => setUserSelectionDialogIsOpen(true)}>
            <Grid item xs={6}>
              <Typography variant="h6" className="whiteText" style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}>
                {getLocalizedString("groupAddMembers")}
              </Typography>
            </Grid>
            <Grid container item xs={6} justify="flex-end">
              <Typography variant="h6" className="whiteText" style={{ fontWeight: 300, fontSize: 16 }}>
                {`${formData.participants.length} ${getLocalizedString("groupSelectedMembers")}`}
              </Typography>
            </Grid>
            <UsersImageArray
              users={participantsObjects}
              employees={employees}
            />
          </Grid>
          {
            Boolean(tags?.length) &&
            <Grid container justify="flex-start" alignItems="center" style={{ padding: '20px 0px', gap: 8 }}>
              <TagList
                tags={tags}
                values={formData.tags}
                handleDelete={handleDeleteTag}
              />
            </Grid>
          }
        </DialogContent>
        <div
          style={{
            display: 'flex',
            padding: 8,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <TagsInput
            tags={tags}
            values={formData.tags}
            onChange={(value) => setFormValue(value, 'tags')}
          />
          <div style={{ display: 'flex', gap: 8 }}>
            <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
            <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString(!isCreate ? "save" : "create")} />
          </div>
        </div>
      </Dialog>
      {
        imagesDialogIsOpen &&
        <GalleryDialog
          imagePreselected={imageIndex}
          open={imagesDialogIsOpen}
          handleClose={() => setImagesDialogIsOpen(false)}
          handleSubmit={(img) => handleSelectImage(img)}
          handleUploadImage={uploadImage}
          handleDeleteUserImage={deleteImage}
        />
      }
      {
        userSelectionDialogIsOpen &&
        <UserSelectionDialog
          open={userSelectionDialogIsOpen}
          initialData={formData.participants}
          users={employees}
          title={'Añadir miembros'}
          selectedUsersTitle={'Miembros seleccionados'}
          handleClose={() => setUserSelectionDialogIsOpen(false)}
          handleSubmit={(data) => setFormValue(data, 'participants')}
          loggedUserIsDisabled
        />
      }
    </>
  )
}

export default FormDialog;