
import React, { useState, useEffect } from "react";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { updateColorAction } from "src/app/actions";
import { useDispatch } from "react-redux";
import { getAvailableColors } from "@icarius-connection/api";
import { CloseIcon } from "@icarius-icons";
import { SketchPicker } from "react-color";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const ChangeColorDialog = (props) => {
  
  const {
    open,
    companyColor,
    handleCloseDialog,
  } = props;
  
  const [color, setColor] = useState(companyColor || "#d50000");
  const [error, setError] = useState(null);
  const [availableColors, setAvailableColors] = useState([]);
  
  const dispatch = useDispatch();

  useEffect(() => {
    getAvailableColors()
      .then((response) => setAvailableColors(response.data.colors))
      .catch((err) => dispatch(openSnackbarAction({ msg: getErrorStringFromError(err), severity: "error" })));
  }, [dispatch])

  const handleChangeColor = (color) => {
    let colorStr = color.hex;
    if (color.rgb.a !== 1) {
      colorStr = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    }
    color.hex = colorStr;
    setColor(color);
  };

  const handleConfirm = () => {
    if (!color) {
      setError(getLocalizedErrorString("selectColor"));
      return;
    }
    dispatch(updateColorAction(color.hex ? color.hex : color)).then(handleCloseDialog);
  };

  const arrColores = availableColors.map(option => option.color);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString("selectColorTitle")}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleCloseDialog} />
        <DialogContent>
          <div>
            <SketchPicker
              color={color}
              onChangeComplete={handleChangeColor}
              width={window.innerWidth < 400 ? 200 : 400}
              disableAlpha={true}
              presetColors={arrColores}
            />
          </div>
          {
            error &&
            <Typography variant={"subtitle1"} className={"error-text"}>
              {error}
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction
            onClick={handleCloseDialog}
            text={getLocalizedString("disagree")}
          />
          <ButtonDialogAction
            onClick={handleConfirm}
            isAccept
            text={getLocalizedString("agree")}
          />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ChangeColorDialog;