import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";
import Chart from "./chart";

const ChartsContainer = ({ data }) => {

  const { theme } = useSelector(getTheme);
  const color = useSelector(getAppColor);

  const ModifiedCard = withStyles({
    root: {
      marginBottom: "1vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:last-child": {
        marginLeft: 5,
      },
      backgroundColor: theme === "light" && "var(--secondaryBackgroundColor)",
    },
  })(Card);

  const ModifiedCardContent = withStyles({
    root: {
      width: "100%",
      padding: 4,
      "&:last-child": {
        paddingBottom: 4,
      },
    },
  })(CardContent);

  const graphicsData = useMemo(() => {
    const getAmountArray = (arr) => {
      return arr.reduce((prev, current) => {
        return (prev[current] ? prev[current]++ : prev[current] = 1, prev)
      }, {});
    };

    return [
      getAmountArray(data.map((item) => item.progressStatus)),
      getAmountArray(data.map((item) => item.statusName)),
    ]
  }, [data])

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12} sm={6}>
        <ModifiedCard>
          <ModifiedCardContent>
            <Chart
              dataType={"bar"}
              color={color}
              theme={theme}
              type={"bar"}
              data={graphicsData[0]}
              title={"Cantidad por avance"}
            />
          </ModifiedCardContent>
        </ModifiedCard>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ModifiedCard>
          <ModifiedCardContent>
            <Chart
              dataType={"pie"}
              color={color}
              theme={theme}
              type={"pie"}
              title={"Cantidad por estado"}
              data={graphicsData[1]}
            />
          </ModifiedCardContent>
        </ModifiedCard>
      </Grid>
    </Grid>
  );
}

export default ChartsContainer;
