import React from "react";
import { TextField } from "mui-rff";
import { Typography, Grid } from "@material-ui/core";

const Text = ({ label, onChange, isTextArea, type, name, useWhiteText, isFloat, isInteger, required, disableInput, useMobileSize, audioPlayerIcon }) => {
  const isTimeZoneString = name === "GO_CITYHOUR";
  const gridSize = useMobileSize ? 12 : 6;
  const timezoneSize = useMobileSize ? 12 : 9;
  const timezoneLabelSize = useMobileSize ? 12 : 3;

  return <Grid container item xs={12} alignItems="center" style={{ height: isTextArea ? "" : 64 }}>
    <Grid container direction="row" alignItems="center" wrap="nowrap" item xs={isTextArea ? 12 : isTimeZoneString ? timezoneLabelSize : gridSize}>
      <Typography variant={"subtitle1"} className={"field"}>
        <span id={name} className={"editable-field-label" + useWhiteText && " whiteText"}>{label}:</span>
      </Typography>
      {
        audioPlayerIcon
      }
      {
        required &&
        <Typography variant={"subtitle1"} className={"errorColor field"} style={{ paddingLeft: 5 }}>
          {"*"}
        </Typography>
      }
    </Grid>
    <Grid item xs={isTextArea ? 12 : isTimeZoneString ? timezoneSize : gridSize}>
      <TextField
        disabled={disableInput}
        className={isTextArea ? "custom-input-95-width-my-data" : isTimeZoneString ? "custom-input-97-width-my-data" : "custom-input-90-width-my-data"}
        name={name}
        type={type}
        inputProps={isFloat && { min: "1", step: "0.05" }}
        margin={"none"}
        onBlur={e => {
          if (e.target.value !== "") {
            e.target.value = isFloat ? (Math.round(e.target.value * 100) / 100).toFixed(2) : isInteger ? (Math.round(e.target.value * 100) / 100).toFixed(0) : e.target.value;
            (isFloat || isInteger) && onChange(e.target.value);
          }
        }}
        onInput={e => {
          if (name !== "DISCLAMER") {
            e.target.value = e.target.value.toString().slice(0, 511);
          }

          if (name === "CLIENTE") {
            e.target.value = e.target.value.toUpperCase()
          }

          onChange(e.target.value);
        }}
        multiline={isTextArea ? true : false}
        rows={isTextArea ? 6 : 1}
        rowsMax={isTextArea ? 6 : 1}
      />
    </Grid>
  </Grid >
}

export default Text;
