import React, { useMemo } from "react";
import { DialogTitle, Dialog, DialogContent } from "@material-ui/core";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import GridMenu from "@icarius-common/gridMenu";
import { getColumnDefByPage } from "@icarius-table/columnDefs";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "95%",
  },
})(Dialog);

const TasksGridDialog = React.memo((props) => {

  const {
    geodivisionStrings,
    open,
    dateFormat,
    departmentOccupation,
    departmentOccupationData,
    statistics,
    setDepartmentOccupation,
    setStatsDialogIsOpen,
    chart
  } = props;

  const handleClose = () => {
    setStatsDialogIsOpen(false)
    setDepartmentOccupation(null)
  }

  const data = departmentOccupation !== null ? departmentOccupationData : statistics
  const isStatistics = departmentOccupation === null

  let title = "Estructura regional";
  let subtitle;

  if (departmentOccupation) {
    const result = chart.config.nodes.find(n => n["Código"] === departmentOccupation);
    if (result) {
      const moreInfo = chart.getNode(result["id"])
      switch (result["type"]) {
        case "Region":
          subtitle = `${geodivisionStrings?.firstLevel}: ${result["Nombre"]}`
          break;
        case "Provincia":
          const parent = chart.get(moreInfo["pid"])
          subtitle = `${geodivisionStrings?.firstLevel}: ${parent["Nombre"]} / ${geodivisionStrings?.secondLevel}: ${result["Nombre"]}`
          break;
        case "Comuna":
          const secondLevel = chart.get(moreInfo["pid"])
          const secondLevelDetailedData = chart.getNode(secondLevel["id"])
          const firstLevel = chart.get(secondLevelDetailedData["pid"])
          subtitle = `${geodivisionStrings?.firstLevel}: ${firstLevel["Nombre"]} / ${geodivisionStrings?.secondLevel}: ${secondLevel["Nombre"]} / ${geodivisionStrings?.thirdLevel}: ${result["Nombre"]}`
          break;
        default:
          subtitle = ""
          break;
      }
    }
  }


  const ownColumnDef = useMemo(() => {
    let auxOwnColumnDef = getColumnDefByPage(!isStatistics ? "geographicalOccupation" : paths.geographicalStructure);

    // Compartido por ambas pantallas
    const firstLevelColumn = auxOwnColumnDef.find((item) => item.field === 'Region');
    firstLevelColumn.headerName = geodivisionStrings?.firstLevel;
    const secondLevelColumn = auxOwnColumnDef.find((item) => item.field === 'Provincia');
    secondLevelColumn.headerName = geodivisionStrings?.secondLevel;
    if (geodivisionStrings?.thirdLevel === "") {
      auxOwnColumnDef = auxOwnColumnDef.filter((item) => item.field !== 'Comuna');
    } else {
      const thirdLevelColumn = auxOwnColumnDef.find((item) => item.field === 'Comuna');
      thirdLevelColumn.headerName = geodivisionStrings?.thirdLevel;
    }

    if (isStatistics) {
      const firstLevelCodeColumn = auxOwnColumnDef.find((item) => item.field === 'Código de region');
      firstLevelCodeColumn.headerName = `Código de ${geodivisionStrings?.firstLevel.toLowerCase()}`;
      const secondLevelCodeColumn = auxOwnColumnDef.find((item) => item.field === 'Código de provincia');
      secondLevelCodeColumn.headerName = `Código de ${geodivisionStrings?.secondLevel.toLowerCase()}`;
      if (geodivisionStrings?.thirdLevel === "") {
        auxOwnColumnDef = auxOwnColumnDef.filter((item) => item.field !== 'Código de comuna');
      } else {
        const thirdLevelCodeColumn = auxOwnColumnDef.find((item) => item.field === 'Código de comuna');
        thirdLevelCodeColumn.headerName = `Código de ${geodivisionStrings?.thirdLevel.toLowerCase()}`;
      }
    }

    return auxOwnColumnDef;
  }, [isStatistics, geodivisionStrings])

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginTop: 10, marginBottom: 10 }}>
          {
            subtitle &&
            <DialogTitle style={{ padding: 0 }}>
              {subtitle}
            </DialogTitle>
          }
          <div style={{ display: 'flex' }}>
            <GridMenu
              hasHelp
            />
          </div>
        </div>
        <CommonPage
          ownColumnDef={ownColumnDef}
          gridTitle={"Estadistica de cargo"}
          rowData={data}
          dateFormat={dateFormat}
          gridHeight={'70vh'}
          ignoreProcessesbyPath
        />
      </DialogContent>
    </StyledDialog>
  );
})

export default TasksGridDialog;