import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, openValidationUsersError, loggedUserCode) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'participants') {
        return isCreate ? [loggedUserCode] : data?.participants?.map((item) => item.key);
      }

      if (fieldName === 'description') {
        return data?.description || '';
      }

      if (fieldName === 'warehouse') {
        return isCreate ? false : data[fieldName];
      }
      
      if (fieldName === 'tags') {
        return data?.tags || [];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'name',
      'description',
      'participants',
      'img',
      'warehouse',
      'tags',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    let schema = yup.object().shape({
      name: yup.string().required(),
      description: yup.string(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const usersToSend = formData.participants.join(',');
      if (usersToSend.length > 0 && usersToSend.length < 1024 && usersToSend.includes(",")) { //con el includes(",") me aseguro que haya al menos otro usuario
        let dataToSend = {
          ...formData,
          participants: formData.participants.join(','),
        };

        isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      } else {
        openValidationUsersError();
      }
    } else {
      invalidDataCallback();
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;