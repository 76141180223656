import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import OwnOrgChart from './orgChart';
import { getAppColor, getGeographicalDivisions } from "src/app/selectors";
import { getOrgStructure, getStatistics, getGeographicalOccupationData } from "../selectors";
import { getGeoStructureAction, getGeographicalOccupation, saveGeoStructureAction } from "../actions";
import StatisticsGridDialog from "./statisticsGridDialog";
import ConfirmActionDialog from "./confirmActionDialog";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DetailsEditDialog from './detailsEditDialog';
import paths from "@icarius-localization/paths";

const GeographicalStructure = ({ history }) => {

  const dispatch = useDispatch();
  const chartRef = useRef();

  const [nodesToUse, setNodesToUse] = useState([]);
  const [nodeAwaitingDelete, setNodeAwaitingDelete] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [departmentOccupation, setDepartmentOccupation] = useState(null);
  const [statsDialogIsOpen, setStatsDialogIsOpen] = useState(false);

  const orgChart = useSelector(getOrgStructure);
  const geodivisionStrings = useSelector(getGeographicalDivisions);
  const statistics = useSelector(getStatistics);
  const departmentOccupationData = useSelector(getGeographicalOccupationData)
  const color = useSelector(getAppColor);
  const title = "Organización geográfica";

  const handleGoBack = () => {
    history.push(paths.locationSettingsBox);
  }

  useEffect(() => {
    setNodesToUse(orgChart.map(member => {
      let data = { ...member, id: member["Código"] }

      if (member["Código"] === "-") {
        data.tags = ["Pais"]
      } else if (member["Código"].includes("!R!")) {
        data.tags = ["Region"]
      } else if (member["Código"].includes("!P!")) {
        data.tags = ["Provincia"]
      } else if (member["Código"].includes("!C!")) {
        data.tags = ["Comuna"]
      }
      return data;
    }))
  }, [orgChart])

  useEffect(() => {
    if (departmentOccupation !== null) {
      dispatch(getGeographicalOccupation(departmentOccupation))
        .then(result => result.data.status === "OK" && setStatsDialogIsOpen(true));
    }
  }, [dispatch, departmentOccupation])

  useEffect(() => {
    dispatch(getGeoStructureAction());
  }, [dispatch])

  const saveChart = () => {
    // Tengo que agarrar los nodos y convertirlos a algo que entienda el server
    let result = [];

    chartRef.current.config.nodes.map(member => {
      if (member["Código"] && member["Nombre"]) {

        // El pid tiene un tratamiento especial
        let pid = member["pid"];

        if (pid.includes("_")) {
          const foundEl = chartRef.current.config.nodes.find(e => member["pid"] === e["id"])
          if (foundEl) {
            pid = foundEl["Código"];
          }
        }

        let data = {
          "Código": member["Código"],
          "Nombre": member["Nombre"],
          pid,
          "type": member["tags"][0]
        }

        return result.push(data)
      }
      return false;
    })
    dispatch(saveGeoStructureAction(result));
  }

  const handleChangeField = (key, text) => {
    let modifiedNode = JSON.parse(JSON.stringify(currentNode));
    modifiedNode[key] = text;
    setCurrentNode(modifiedNode)
  }
  const closeConfirmActionDialog = () => {
    setConfirmDialogIsOpen(false)
  }

  const setChartRef = (chart) => {
    chartRef.current = chart;
  }

  const deleteNode = () => {
    if (chartRef.current) {
      let oldNode = chartRef.current.get(nodeAwaitingDelete);
      if (oldNode) {
        chartRef.current.removeNode(nodeAwaitingDelete);
        setNodeAwaitingDelete(null)
      }
    }
  }


  const cancelRefClick = () => {
    setDialogIsOpen(false)
    if (editMode && currentNode["Nombre"] === "" && currentNode["Código"] === "") {
      chartRef.current.removeNode(currentNode["id"]);
    }
    setEditMode(false)
  };

  const closeRefClick = () => {
    setDialogIsOpen(false)
    setEditMode(false)
  }

  const saveRefClick = () => {
    let node = JSON.parse(JSON.stringify(chartRef.current.get(currentNode["id"])));
    let msg = ""
    if (node && currentNode["Código"] && currentNode["Nombre"]) {

      // En caso de ser un nodo nuevo...
      if (node["id"].substr(0, 1) === "_") {
        // Primero, tengo que verificar que no exista ya ese código en este nivel
        const coincidence = chartRef.current.config.nodes.find(n => (
          n["Código"].includes("!") ? n["Código"].substr(3) === currentNode["Código"] : n["Código"] === currentNode["Código"])
          && n["id"] !== currentNode["id"] && n["tags"].includes(currentNode["tags"][0])
        );
        if (coincidence) {
          msg = "Existe otro nodo con el mismo código.";
          showSnackbarError(msg)
        } else {
          updateNode(currentNode)
        }
      } else {
        Object.keys(currentNode).forEach(e => node[e] = currentNode[e])
        updateNode(node)
      }
    } else {
      msg = "Complete el nombre y código del nodo.";
      showSnackbarError(msg)
    }

  };

  const updateNode = (node) => {
    chartRef.current.updateNode(node);
    setDialogIsOpen(false)
  }

  const showSnackbarError = useCallback((msg) => {
    dispatch(openSnackbarAction({ msg: msg, severity: "error" }));
  }, [dispatch])


  return (
    <CommonPage
      title={title}
      gridTitle={getLocalizedString("directory")}
      isNotGridPage
    >
      <div style={{ height: '100%' }}>
        <OwnOrgChart
          nodes={nodesToUse}
          dispatch={dispatch}
          color={color}
          setStatsDialogIsOpen={setStatsDialogIsOpen}
          setDepartmentOccupation={setDepartmentOccupation}
          setEditMode={setEditMode}
          setDialogIsOpen={setDialogIsOpen}
          setConfirmDialogIsOpen={setConfirmDialogIsOpen}
          setCurrentNode={setCurrentNode}
          setNodeAwaitingDelete={setNodeAwaitingDelete}
          setChartRef={setChartRef}
          handleGoBack={handleGoBack}
          geodivisionStrings={geodivisionStrings}
        />
      </div>
      <ConfirmActionDialog
        open={confirmDialogIsOpen}
        nodeId={nodeAwaitingDelete}
        handleClose={closeConfirmActionDialog}
        deleteNode={deleteNode}
        saveChart={saveChart}
      />
      {
        dialogIsOpen &&
        <DetailsEditDialog
          dialogIsOpen={dialogIsOpen}
          handleClose={() => setDialogIsOpen(false)}
          editMode={editMode}
          currentNode={currentNode}
          geodivisionStrings={geodivisionStrings}
          handleChangeField={handleChangeField}
          cancelRefClick={cancelRefClick}
          closeRefClick={closeRefClick}
          saveRefClick={saveRefClick}
        />
      }
      <StatisticsGridDialog
        title={title}
        open={statsDialogIsOpen}
        departmentOccupation={departmentOccupation}
        departmentOccupationData={departmentOccupationData}
        statistics={statistics}
        geodivisionStrings={geodivisionStrings}
        chart={chartRef.current}
        setDepartmentOccupation={setDepartmentOccupation}
        setStatsDialogIsOpen={setStatsDialogIsOpen}
      />
    </CommonPage>
  );
}

export default GeographicalStructure;