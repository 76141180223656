import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import CustomEditableField from "./display/editableField";
import PlayButton from "@icarius-common/audioPlayer/components/playButton";

const Form = (props) => {

    const {
        name,
        title,
        subtitle,
        color,
        fields,
        values,
        handleTextChange,
        attachImage
    } = props;

    const getAudioPlayer = (name) => {
        switch (name) {
            case "DISCLAMER":
                return <div style={{ padding: 10 }}><PlayButton audioName="Consentimiento para firma digital" title="Consentimiento para firma digital" /></div>;
            case "GRUPOEMP":
                return <div style={{ padding: 10 }}><PlayButton audioName="Grupo para acceso de administradores" title="Grupo para acceso de administradores" /></div>;
            default: return null;
        }
    }

    const generateField = (field, index) => {
        const useMobileSize = window.innerWidth <= 600;
        const textAreaStyle = { height: "170px" };
        const otherStyle = { height: `${useMobileSize ? 160 : 64}px` };
        const fieldName = Object.keys(field)[0];

        if (field[fieldName] && field[fieldName].type) {
            const { name, type, options } = field[fieldName];
            const isImage = type === "img";
            const value = values[fieldName];
            const checkboxData = { value: value || false };
            const imgStyle = { height: "250px" };

            return (
                <Grid
                    key={index}
                    item
                    lg={isImage ? 3 : false}
                    sm={isImage ? 6 : false}
                    xs={isImage ? 12 : false}
                    style={type === "textarea" ? textAreaStyle : isImage ? imgStyle : otherStyle}
                >
                    <CustomEditableField
                        useWhiteText
                        required={false}
                        key={index}
                        name={fieldName}
                        label={name || getLocalizedString(fieldName)}
                        type={type}
                        options={options || []}
                        companyColor={color}
                        handleTextChange={handleTextChange}
                        handleSelectChange={(name, value) => handleTextChange(name, value, values)}
                        useMobileSize={useMobileSize}
                        checkboxData={checkboxData}
                        value={value}
                        attachImage={isImage ? attachImage : null}
                        audioPlayerIcon={getAudioPlayer(fieldName)}
                    />
                </Grid>
            )
        }
        return <Grid item key={index} style={otherStyle}><div></div></Grid>;
    }

    const titleStyle = {
        fontWeight: 400,
        fontSize: '28px',
        lineHeight: "28px",
        textTransform: "uppercase",
        marginBottom: 10,
    };

    const subtitleStyle = {
        fontWeight: 300,
        fontSize: '20px',
        lineHeight: "20px",
        marginBottom: 10,
    };

    const isContainer = name === "logosAndCustomization";
    const usesTwoColumns = Boolean(name !== "timezones" && name !== "logosAndCustomization");

    return (
        <Grid container className={"personal-data-form"}>
            <Grid container alignItems="center" item xs={12}>
                <Typography className={"whiteText"} style={name === "hideFieldsPeopleMaster" ? { textDecoration: "underline" } : name === "colaboratorPermissions" ? subtitleStyle : titleStyle}>
                    {title || name}
                </Typography>
            </Grid>
            {
                subtitle &&
                <Typography className={"whiteText"} style={subtitleStyle}>
                    {subtitle}
                </Typography>
            }
            <Grid container>
                <Grid item container={isContainer} md={name === "timezones" || name === "logosAndCustomization" ? 12 : 6} xs={12}>
                    {fields[0].map((field, index) => generateField(field, index))}
                </Grid>
                {
                    usesTwoColumns &&
                    <Grid item md={6} xs={12}>
                        {fields[1].map((field, index) => generateField(field, index))}
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default Form;