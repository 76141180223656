import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,  
  dateFormat: "yyyy/mm/dd",
  currencyLocalization: "da",

  groups: [],  
  groupsToFilter: [],  
  queries: [],
  filters: {},
  queryResult: [],

  selectableColumns: [],
  nmark: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_QUERY_GROUPS:
      return { ...state, isLoading: true };
    case actionTypes.GET_QUERY_GROUPS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        groups: action.payload.groups,
        groupsToFilter: action.payload.groupsToFilter,
      };
    case actionTypes.GET_QUERY_GROUPS_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.GET_QUERIES:
      return { ...state, isLoading: true };
    case actionTypes.GET_QUERIES_FULFILLED:
      return {
        ...state,
        isLoading: false,
        queries: action.payload.queries,
      };
    case actionTypes.GET_QUERIES_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.GET_QUERY_RESULT:
      return { ...state, isLoading: true };
    case actionTypes.GET_QUERY_RESULT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        queryResult: action.payload.queryResult,
        dateFormat: action.payload.dateFormat,
        filters: action.payload.filters,
        currencyLocalization: action.payload.currencyLocalization,
        selectableColumns: action.payload.selectableColumns,
        nmark: action.payload.nmark,
      };
    case actionTypes.GET_QUERY_RESULT_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.IMPORT_EXPORT:
      return { ...state, isLoading: true };
    case actionTypes.IMPORT_EXPORT_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.IMPORT_EXPORT_REJECTED:
      return { ...state, isLoading: false };
    case actionTypes.RESET_EXTRA_DATA:
      return { ...state, selectableColumns: [], nmark: null };
    case actionTypes.RESET_ROWS:
      return { ...state, queryResult: [], filters: [] };
    default:
      return state;
  }
}