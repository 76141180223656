import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
    getGeneralValuesAPI,
    setGeneralValuesAPI,
    deleteGeneralValuesAPI
} from "@icarius-connection/api";

export const getGeneralValuesFromServer = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await getGeneralValuesAPI();
        let rows = response.data && response.data.data;

        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { rows },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const setGeneralValuesToServer = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_ROWS });
    try {
        let response = await setGeneralValuesAPI({ data });
        let rows = response.data && response.data.data;

        dispatch({
            type: actionTypes.SET_ROWS_FULFILLED,
            payload: { rows },
        });
        dispatch(openSnackbarAction({ msg: 'Valores actualizados con éxito', severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.SET_ROWS_REJECTED, payload: e });
        if(e.response.data.status === 'DUPLICATED_DATA'){
            dispatch(openSnackbarAction({ msg: "No pueden existir valores con el mismo código o nombre", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const deleteGeneralValuesInServer = (codes) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_ROWS });
    try {
        let response = await deleteGeneralValuesAPI({ codes });
        let rows = response.data && response.data.data;

        dispatch({
            type: actionTypes.DELETE_ROWS_FULFILLED,
            payload: { rows },
        });
        
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.DELETE_ROWS_REJECTED, payload: e });
        if(e.response.data.status === 'IN_USE'){
            dispatch(openSnackbarAction({ msg: "No es posible eliminar los valores porque los valores seleccionados están en uso", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};