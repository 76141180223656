import React from "react";
import {
    Grid,
    Typography,
    TableBody,
    Table,
    TableContainer,
    TableHead,
    Paper,
    TableRow,
    TableCell,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const MultipleItemTable = (props) => {

    const {
        data,
        filters,
        title,
        color,
        overTitle,
    } = props;

    let header = Object.keys(data[0]);

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: "#FFFFFF",
            color: "#000000",
            borderBottom: `1px solid ${color}`,
            whiteSpace: "nowrap"
        },
        body: {
            backgroundColor: "#FFFFFF",
            color: "#000000",
            borderBottom: "1px solid #FFFFFF",
            whiteSpace: "nowrap"
        }
    }))(TableCell);

    return (
        <Grid container item xs={12}>
            {
                overTitle &&
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ color: color }}>
                        {`${overTitle} (${data.length})`}
                    </Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                header.map((item, index) => {
                                    return <StyledTableCell key={index}>
                                        <Typography>{item}</Typography>
                                    </StyledTableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((item, index) => {
                                const array = Object.values(item).map((subitem, sIndex) => {
                                    return (
                                        <StyledTableCell key={sIndex}>
                                            <Typography>
                                                {filters[header[sIndex]] === "Mon" ? formatNumberOrReturnUndefined(subitem, 2, 2) : subitem}
                                            </Typography>
                                        </StyledTableCell>
                                    )
                                })

                                return <TableRow key={index}>{array}</TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}

export default MultipleItemTable;