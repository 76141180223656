import React from "react";
import QueryForm from "./queryForm";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const FormContainer = (props) => {

  const { isLoading, data, parameterCode, paramNumToUse, handleClose } = props;

  const isEdit = Boolean(data);

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => handleClose(false)}
      type={"goBack"}
    />

  return (
    <CommonPage
      columnDefPage={paths.queryDefinition}
      title={`${isEdit ? 'Editar' : 'Crear'} parámetro de consulta${isEdit ? `: ${data.name} - ${data.paramNum}` : ''}`}
      menuItems={[goBackItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <QueryForm
        data={data}
        paramNumToUse={paramNumToUse}
        parameterCode={parameterCode}
        handleClose={handleClose}
      />
    </CommonPage>
  )
}
export default FormContainer;
