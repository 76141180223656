import NAME from "./constants";

export const getLocale = store => store[NAME].currencyLocalization;
export const getDateFormat = store => store[NAME].dateFormat;

export const getIsLoading = store => store[NAME].isLoading;
export const getQueryGroups = store => store[NAME].groups;
export const getGroupsToFilter = store => store[NAME].groupsToFilter;
export const getQueries = store => store[NAME].queries;
export const getFilters = store => store[NAME].filters;
export const getQueryResult = store => store[NAME].queryResult;
export const getSelectableColumns = store => store[NAME].selectableColumns;
export const getNMark = store => store[NAME].nmark;
