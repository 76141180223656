import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import ProgressRenderer from "@icarius-table/renderersAndFilters/progressRenderer";
import ProgressFilter from "@icarius-table/renderersAndFilters/progressFilter";
import { CheckIcon } from "@icarius-icons/index";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import useTemplates from "@icarius-common/templates/components/useTemplates";

const GridView = (props) => {

  const {
    isLoading,
    level,
    data,
    ownColumnDef,
    canOperate,
    periodSelector,
    handlers,
  } = props;

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('GOALS_AND_OBJECTIVES');

  const goBackButton = () => (
    <CustomIconButton
      title={'Volver'}
      onClick={handlers.handleCloseGrid}
      type={"goBack"}
    />
  )

  const createButton = () => canOperate && (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={handlers.handleOpenCreateDialog}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialogByGrid(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => canOperate && (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialogByGrid(gridRef)}
      type={"delete"}
    />
  )

  const annotationsButton = (gridRef) => level !== 'C' && (
    <CustomIconButton
      title={'Anotaciones del colaborador'}
      onClick={() => handlers.handleOpenAnnotations(gridRef)}
      type={"annotations"}
    />
  )

  const verifyButton = (gridRef) => (
    <CustomIconButton
      title={'Verificar ponderados'}
      onClick={() => handlers.handleVerifyByGrid(gridRef)}
    >
      <CheckIcon />
    </CustomIconButton>
  )

  const graphicsButton = () => (
    <CustomIconButton
      title={"Gráficos"}
      onClick={handlers.handleOpenGraphics}
      type={"graphics"}
    />
  )

  const customHeader = (gridRef) => (
    <>
      <div style={{ minWidth: 220, maxWidth: 400 }}>
        {periodSelector}
      </div>
      {
        level !== 'C' &&
        <div style={{ paddingLeft: 10, display: 'flex' }}>
          <TemplatesHeader
            gridRef={gridRef}
            user={user}
            templates={templates}
            selectedTemplate={selectedTemplate}
            getRole={getRole}
            handleImportTemplates={handleImportTemplates}
            handleExportTemplates={handleExportTemplates}
            handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
            handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
            handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
            handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
          />
        </div>
      }
    </>
  )

  const child = (gridRef) =>
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
    </>

  const isLoadingEverything = isLoading || isLoadingTemplates;

  return (
    <CommonPage
      isLoading={isLoadingEverything}
      title={level === 'C' ? 'Mis metas y objetivos' : 'Metas y objetivos'}
      gridTitle={level === 'C' ? 'Mis metas y objetivos' : 'Metas y objetivos'}
      ownColumnDef={ownColumnDef}
      rowData={data}
      menuItems={[goBackButton, createButton, modifyButton, deleteButton, verifyButton, annotationsButton, graphicsButton]}
      customHeader={customHeader}
      hasExpand
      hasHelp
      frameworkComponents={{ ProgressRenderer, ProgressFilter }}
      codeFieldName={'code'}
    >
      {child}
    </CommonPage>
  )
}

export default GridView;