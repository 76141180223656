import { useState } from "react";
import * as yup from 'yup';
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";

const useHandleForm = (data, createCallback, modifyCallback, fetchTasks, invalidDataCallback, presetPeriod, userData, taskList, targetList) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {
    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'startDate') {
        return isCreate ? null : (data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : null);
      }

      if (fieldName === 'endDate') {
        return isCreate ? null : (data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : null);
      }

      if (fieldName === 'revisionDate') {
        return isCreate ? null : (data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : null);
      }

      if (fieldName === 'progressDate') {
        return isCreate ? new Date() : (data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : null);
      }

      if (fieldName === 'status') {
        return isCreate ? 'A' : data[fieldName];
      }

      if (fieldName === 'progress') {
        return isCreate ? 0 : data[fieldName];
      }

      if (fieldName === 'period') {
        return isCreate ? presetPeriod : data[fieldName];
      }

      if (fieldName === 'targetWeighting') {
        return isCreate ? 0 : data[fieldName];
      }

      if (fieldName === 'hasCollaboratorApproval') {
        return isCreate ? 'N' : data[fieldName];
      }

      if (fieldName === 'hasManagerApproval') {
        return isCreate ? (userData.level === 'C' ? 'Y' : 'N') : data[fieldName];
      }

      if (fieldName === 'target') {
        return isCreate ? '' : data['targetCode'];
      }

      if (fieldName === 'code') {
        if (userData.level === 'C') return userData.code;
        return isCreate ? '' : data[fieldName];
      }

      return isCreate ? '' : data[fieldName];
    }

    const fieldNames = [
      "code",
      "period",
      "startDate",
      "endDate",
      "revisionDate",
      "progress",
      "progressDate",
      "status",
      "relatedTask",
      "hasCollaboratorApproval",
      "hasManagerApproval",
      "approvedByCollaborator",
      "approvedByManager",
      "targetDescription",
      "targetWeighting",
      "targetCode",
      "targetName",
      // auxiliares
      "targetType",
      "target",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      "code": yup.string().required(),
      "period": yup.string().required(),
      "startDate": yup.date().required(),
      "endDate": yup.date().required(),
      "revisionDate": yup.date().nullable(),
      "progress": yup.number(),
      "progressDate": yup.date().required(),
      "status": yup.string().required(),
      "relatedTask": yup.string(),
      "targetDescription": yup.string(),
      "targetWeighting": yup.number(),
      "targetCode": yup.string().required(),
      "targetName": yup.string().required(),
      "hasCollaboratorApproval": yup.string()
        .when([], {
          is: () => userData.level === 'M',
          then: yup.string().required()
        }),
      "hasManagerApproval": yup.string()
        .when([], {
          is: () => userData.level === 'C',
          then: yup.string().required()
        }),
      "approvedByCollaborator": yup.string()
        .when([], {
          is: () => !isCreate && data.editableApprovedByCollaborator,
          then: yup.string().required()
        }),
      "approvedByManager": yup.string()
        .when([], {
          is: () => !isCreate && data.editableApprovedByManager,
          then: yup.string().required()
        }),
    });

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
        "startDate": formatDateYYYYMMDD(formData.startDate),
        "endDate": formatDateYYYYMMDD(formData.endDate),
        "progressDate": formatDateYYYYMMDD(formData.progressDate),
        "revisionDate": formData.revisionDate ? formatDateYYYYMMDD(formData.revisionDate) : null,
      };

      isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback(Boolean(formData.code));
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'code') {
      setFormData({
        ...formData,
        [fieldName]: value,
        relatedTask: '',
      });

      fetchTasks(value);
      return;
    }

    if (fieldName === 'relatedTask') {
      const task = taskList.find((item) => item.key === value);
      setFormData({
        ...formData,
        [fieldName]: value,
        progress: task?.progress || 0,
        progressDate: task?.progressDate ? createDateFromDDMMYYYY(task.progressDate) : new Date(),
      });
      return;
    }

    if (fieldName === 'progress') {
      setFormData({
        ...formData,
        [fieldName]: value,
        progressDate: new Date(),
      });
      return;
    }

    if (fieldName === 'period') {
      setFormData({
        ...formData,
        [fieldName]: value,
        startDate: null,
        endDate: null,
        revisionDate: null,
      });
      return;
    }

    if (fieldName === 'startDate') {
      setFormData({
        ...formData,
        [fieldName]: value,
        endDate: null,
        revisionDate: null,
      });
      return;
    }

    if (fieldName === 'status' && ['D', 'P'].includes(value)) {
      setFormData({
        ...formData,
        [fieldName]: value,
        targetWeighting: 0,
      });
      return;
    }

    if (fieldName === 'targetType') {
      setFormData({
        ...formData,
        [fieldName]: value,
        target: '',
        targetCode: '',
        targetName: '',
      });
      return;
    }

    if (fieldName === 'target') {
      const filteredTargetList = targetList[formData.targetType]?.find((item) => item.key === value);
      setFormData({
        ...formData,
        [fieldName]: value,
        targetCode: filteredTargetList?.key || '',
        targetName: filteredTargetList?.value || '',
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;