import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition.js";
import ChartsContainer from "./chartsContainer";

const GraphsDialog = (props) => {

  const {
    open,
    data,
    periodName,
    handleClose,
  } = props;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Gráficos de las metas y objetivos: ${periodName}`}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <div style={{ width: "100%" }} className="container relative flex flex-row justify-between items-center">
          <ChartsContainer
            data={data}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default GraphsDialog;
