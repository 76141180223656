import React, { useMemo } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import FabButton from "@icarius-common/fabButton";
import SendMailDialog from "@icarius-common/mailDialog/sendMailDialog";
import NoGroups from "../noGroups";
import FormDialog from "./dialogs/formDialog";
import MembersDialog from "./dialogs/membersDialog";
import GroupList from "./groupList";
import useMyGroups from "./useMyGroups";

const MyGroups = () => {

  const {
    isLoading,
    isSendingMail,
    groups,
    employees,
    tags,
    state,
    handlers,
  } = useMyGroups();

  const filteredGroups = useMemo(() => {
    if (!state.tagFilter) return groups;
    return groups.filter((item) => item.tags?.includes(state.tagFilter));
  }, [groups, state.tagFilter])

  return (
    <CommonPage
      title={getLocalizedString("myGroupsTitle")}
      isLoading={isLoading || isSendingMail}
      isNotGridPage
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 10, margin: '0px 10px 10px' }}>
        <FabButton
          title={getLocalizedString("newGroup")}
          onClick={() => handlers.handleOpenGroupDialog()}
        />
        {
          Boolean(tags?.length) &&
          <div style={{ width: 300, minWidth: 300, paddingLeft: 10 }}>
            <FormControl fullWidth>
              <InputLabel shrink>{'Etiquetas'}</InputLabel>
              <Select
                value={state.tagFilter}
                onChange={(e) => handlers.handleFilterByTag(e.target.value)}
                margin={"none"}
                displayEmpty
              >
                <MenuItem className={"whiteText"} value={''}>
                  {'Todos'}
                </MenuItem>
                {
                  tags?.map((item) => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}
                    >
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
        }
      </div>
      {
        !Boolean(groups?.length) && !isLoading &&
        <NoGroups
          onClick={handlers.handleOpenGroupDialog}
        />
      }
      {
        Boolean(filteredGroups?.length) &&
        <GroupList
          tags={tags}
          groups={filteredGroups}
          handleOpenEditGroupDialog={handlers.handleOpenGroupDialog}
          handleOpenDeleteGroupDialog={handlers.handleOpenDeleteGroupDialog}
          handleOpenLeaveGroupDialog={handlers.handleOpenLeaveDialog}
          handleOpenMembersDialog={handlers.handleOpenMembersDialog}
          handleOpenMailDialog={handlers.handleOpenMailDialog}
        />
      }
      {
        state.formDialogIsOpen &&
        <FormDialog
          open={state.formDialogIsOpen}
          data={state.selectedGroup}
          employees={employees}
          tags={tags}
          handleClose={handlers.handleCloseGroupDialog}
        />
      }
      {
        state.membersDialogIsOpen &&
        <MembersDialog
          open={state.membersDialogIsOpen}
          participants={state.selectedGroup.participants}
          employees={employees}
          handleClose={handlers.handleCloseMembersDialog}
        />
      }
      {
        state.mailDialogIsOpen && !isLoading && !isSendingMail &&
        <SendMailDialog
          open={state.mailDialogIsOpen}
          employees={state.mailUsers}
          groupName={state.groupName}
          handleClose={handlers.handleCloseMailDialog}
          origin={"Groups"}
        />
      }
    </CommonPage >
  )
}

export default MyGroups;