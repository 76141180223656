import React from "react";
import {
  Grid,
  Typography,
  Tooltip,
} from "@material-ui/core";
import ProfileImg from "@icarius-common/profileImg";
import { VariableSizeList } from "react-window";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const UserSection = React.memo((props) => {

  const {
    employees,
    handleClick,
    screenWidth,
  } = props;

  const color = useSelector(getAppColor);

  const Column = ({ index, style }) => {
    const employee = employees[index];
    const image = employee["FOTOGRAFIA"] || employee["img"] || employee["image"] || "";

    return (
      <div style={{ ...style, display: 'flex' }}>
        <Grid container alignItems="center" key={employee.key}>
          <Grid container item xs={2}>
            <ProfileImg
              image={image}
              size={40}
            />
          </Grid>
          <Grid item xs={10} style={{ cursor: "pointer" }} onClick={() => handleClick(employee.key)}>
            <Tooltip title={employee.name}>
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: employee.isChecked ? 600 : 100,
                  color: employee.isChecked ? color : 'var(--mainText)',
                }}
              >
                {employee.name}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <VariableSizeList
      className="List"
      layout="horizontal"
      itemCount={employees.length}
      itemSize={() => 300}
      height={80}
      width={screenWidth}
    >
      {Column}
    </VariableSizeList>
  )
})

export default UserSection;