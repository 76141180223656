import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import useHandleFormDuplicate from "./useHandleFormDuplicate";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const gridStyle = { height: 75, padding: "0px" };

const DuplicateDialog = (props) => {

  const { open, data, handleConfirm, handleClose } = props;

  const dispatch = useDispatch();

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleFormDuplicate(data, handleConfirm, openValidationError);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Duplicar definición de consulta: ${data.code}`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* codigo */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Codigo"}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Nombre"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* group */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Grupo de ubicación"}
            value={formData.group}
            onChange={(e) => setFormValue(e.target.value, "group")}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* secuencia */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Secuencia de aparición'}
            type="number"
            value={formData.sequence}
            onChange={(e) => setFormValue(e.target.value.substring(0, 3), "sequence")}
            inputProps={{ min: 1, max: 999, maxLength: 3 }}
            onBlur={(e) => e.target.value = e.target.value.substring(0, 3)}
          />
        </Grid>
        
      </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;
