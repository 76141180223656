import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'code',
            headerName: "Código de empleado",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'name',
            headerName: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'targetType',
            headerName: "Tipo de objetivo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'statusName',
            headerName: "Estado del objetivo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'assignerName',
            headerName: "Asignador del objetivo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'targetName',
            headerName: "Objetivo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'targetWeighting',
            headerName: "Ponderado del objetivo",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'targetDescription',
            headerName: "Descripción del objetivo",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["goalsAndObjectivesAllPeriods"],
        config: {
            field: 'periodString',
            headerName: "Período vinculado al objetivo",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'startDate',
            headerName: "Fecha de asignación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'endDate',
            headerName: "Fecha de término",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'progressStatus',
            headerName: "Estado de avance",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'progress',
            headerName: "Progreso",
            cellRenderer: "ProgressRenderer",
            filter: "ProgressFilter",
            cellClass: "percentageValue",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'progressDate',
            headerName: "Fecha del progreso",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'feedbacksQuantity',
            headerName: "Feedbacks",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'relatedTask',
            headerName: "Tarea vinculada",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.goalsAndObjectives, "goalsAndObjectivesAllPeriods"],
        config: {
            field: 'revisionDate',
            headerName: "Fecha de revisión",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
]