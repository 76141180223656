import React from "react";
import { Checkboxes } from "mui-rff";

import { Grid, Typography } from "@material-ui/core";

const Checkbox = ({ label, checkboxData, onChange, name, useWhiteText, required, disableInput, useMobileSize, audioPlayerIcon }) => {
  return (
    <Grid container item xs={12} alignItems="center" style={{ height: 64 }}>
      <Grid container direction="row" alignItems="center" wrap="nowrap" item xs={useMobileSize ? 12 : 6}>
        <Typography variant={"subtitle1"} className={"field"}>
          <span id={name} className={"editable-field-label" + useWhiteText && "whiteText"}>{label}:</span>
        </Typography>
        {
          audioPlayerIcon
        }
        {
          required &&
          <Typography variant={"subtitle1"} className={"errorColor field"} style={{ paddingLeft: 5 }}>
            {"*"}
          </Typography>
        }
      </Grid>
      <Grid item xs={useMobileSize ? 12 : 6}>
        <div>
          <Checkboxes
            name={name}
            disabled={disableInput}
            style={{ color: "var(--icons)" }}
            onChange={(e) => onChange(e.target.checked)}
            data={checkboxData} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Checkbox;
