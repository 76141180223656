import NAME from "./constants";

export const GET_GROUPS_DATA = NAME + "/GET_GROUPS_DATA";
export const GET_GROUPS_DATA_FULFILLED = NAME + "/GET_GROUPS_DATA_FULFILLED";
export const GET_GROUPS_DATA_REJECTED = NAME + "/GET_GROUPS_DATA_REJECTED";

export const CREATE_GROUP = NAME + "/CREATE_GROUP";
export const CREATE_GROUP_FULFILLED = NAME + "/CREATE_GROUP_FULFILLED";
export const CREATE_GROUP_REJECTED = NAME + "/CREATE_GROUP_REJECTED";

export const EDIT_GROUP = NAME + "/EDIT_GROUP";
export const EDIT_GROUP_FULFILLED = NAME + "/EDIT_GROUP_FULFILLED";
export const EDIT_GROUP_REJECTED = NAME + "/EDIT_GROUP_REJECTED";

export const DELETE_GROUP = NAME + "/DELETE_GROUP";
export const DELETE_GROUP_FULFILLED = NAME + "/DELETE_GROUP_FULFILLED";
export const DELETE_GROUP_REJECTED = NAME + "/DELETE_GROUP_REJECTED";

export const LEAVE_GROUP = NAME + "/LEAVE_GROUP";
export const LEAVE_GROUP_FULFILLED = NAME + "/LEAVE_GROUP_FULFILLED";
export const LEAVE_GROUP_REJECTED = NAME + "/LEAVE_GROUP_REJECTED";

export const GET_IMAGES = NAME + "/GET_IMAGES";
export const GET_IMAGES_FULFILLED = NAME + "/GET_IMAGES_FULFILLED";
export const GET_IMAGES_REJECTED = NAME + "/GET_IMAGES_REJECTED";

export const UPLOAD_IMAGE = NAME + "/UPLOAD_IMAGE";
export const UPLOAD_IMAGE_REJECTED = NAME + "/UPLOAD_IMAGE_REJECTED";
export const UPLOAD_IMAGE_FULFILLED = NAME + "/UPLOAD_IMAGE_FULFILLED";

export const DELETE_IMAGE = NAME + "/DELETE_IMAGE";
export const DELETE_IMAGE_REJECTED = NAME + "/DELETE_IMAGE_REJECTED";
export const DELETE_IMAGE_FULFILLED = NAME + "/DELETE_IMAGE_FULFILLED";

export const CLEAN_STATE = NAME + "/CLEAN_STATE";