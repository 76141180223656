import React from "react";
import {
  Card,
  CardContent,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import DotsMenu from "@icarius-common/dotsMenu";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { getProgressColor } from "@icarius-utils/colors";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import moment from "moment";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const splitIntoLines = (text) => {
  return text
    .split('\n')
    .map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    })
};

const getStatusColor = (status) => {
  if (status === 'Activo') return 'var(--mainText)';
  if (status === 'Detenido' || status === 'Postergado') return 'var(--progressLow)';
  return 'var(--progressHigh)';
}

const getDelayOrRemaining = (endDate) => {
  const days = moment(createDateFromDDMMYYYY(endDate).setHours(0, 0, 0, 0).valueOf()).diff(moment(new Date().setHours(0, 0, 0, 0).valueOf()), 'days');

  if (!days) return <span key={4}>{` Vence hoy.`}</span>;

  if (days > 0) {
    return (
      <span key={4} style={{ color: 'var(--progressHigh)' }}>{` Faltan ${days} días.`}</span>
    )
  }

  return (
    <span key={4} style={{ color: 'var(--progressLow)' }}>{` Atraso de ${Math.abs(days)} días.`}</span>
  )
}

const DataDisplay = ({ title, value, horizontal }) => {
  return (
    <Grid container item direction={horizontal ? "row" : "column"} xs={12} spacing={horizontal ? 1 : 0}>
      <Grid item>
        <Typography className="whiteText">
          {title}:
        </Typography>
      </Grid>
      <Grid item>
        <Typography style={{ fontWeight: 600, color: 'var(--mainText)' }}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

const Objective = ({ handlers, data, canOperate }) => {

  const color = useSelector(getAppColor);

  const menu = canOperate && (
    <DotsMenu>
      <MenuItem onClick={() => handlers.handleOpenEditDialogByUser(data.internalCode)}>
        Editar
      </MenuItem>
      <MenuItem onClick={() => handlers.handleOpenDeleteDialogByUser(data)}>
        Eliminar
      </MenuItem>
    </DotsMenu>
  );

  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={12} style={{ marginBottom: 20, paddingBottom: 10, borderBottom: `1px solid ${color}` }}>
            <Grid container item xs={11} direction="column">
              <Typography className="whiteText" style={{ fontSize: 20 }}>{data.targetName}</Typography>
              <Typography style={{ fontWeight: 600, color: getStatusColor(data.statusName) }}>
                {data.statusName}
              </Typography>
            </Grid>
            <Grid container item xs={1}>
              {menu}
            </Grid>
          </Grid>
          <DataDisplay title={'Asignado por'} value={data.assignerName} />
          <div style={{ padding: 10 }} />
          <DataDisplay title={'Fecha de asignación'} value={data.startDate} horizontal />
          <DataDisplay title={'Fecha de termino'} value={data.endDate} horizontal />
          <DataDisplay title={'Estado de avance'} value={data.progressStatus} horizontal />
          <DataDisplay
            title={'Porcentaje de progreso'}
            value={[
              <span key={1} style={{ color: getProgressColor(data.progress * 100) }}>{`${formatNumberExactDecimals(data.progress * 100, 2)}%`}</span>,
              <span key={2}> al </span>,
              <span key={3}>{`${data.progressDate}.`}</span>,
              ['Por hacer', 'En curso'].includes(data.progressStatus) ? getDelayOrRemaining(data.endDate) : null
            ]}
            horizontal
          />
          {
            data.pendingApproval === 'Si' &&
            <Grid container item xs={12}>
              <Typography style={{ fontWeight: 600, color: 'var(--progressLow)' }}>
                {'Debes aprobar el objetivo'}
              </Typography>
            </Grid>
          }
          <div style={{ padding: 10 }} />
          <DataDisplay title={'Descripción del objetivo'} value={splitIntoLines(data.targetDescription || '-')} />
          <div style={{ padding: 10 }} />
          <DataDisplay title={'Feedbacks'} value={data.feedbacksQuantity} horizontal />
          <DataDisplay title={'Tarea vinculada'} value={data.relatedTask || 'Ninguna'} horizontal />
          <div style={{ padding: 10 }} />
          <DataDisplay title={'Ponderado'} value={`${data.targetWeighting}%`} horizontal />
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Objective;