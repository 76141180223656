import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import ScreenSelector from "@icarius-common/screenSelector";
import CommonPage from "@icarius-common/commonPage";
import { Grid, Typography } from "@material-ui/core";

const GroupSelector = (props) => {

  const {
    groups,
    handleClick,
  } = props;

  const data = (() => {
    return groups.map((item) => {
      return {
        name: item,
        ignorePathValidation: true,
        ignoreDecoration: true,
        onClick: () => handleClick(item),
      }
    })
  })();

  const title = getLocalizedString("geoanalysisTitle");

  if (!data?.length) {
    return (
      <CommonPage
        title={title}
        isNotGridPage
      >
        <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
          <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
            {'No se encontraron consultas'}
          </Typography>
        </Grid>
      </CommonPage>
    )
  }

  return (
    <ScreenSelector
      title={title}
      data={data}
      small
    />
  );
}

export default GroupSelector;