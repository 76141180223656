import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getGeoanalysisGroupsAPI,
  getGeoanalysisQueriesAPI,
  executeGeoanalysisQueryAPI,
  exportGeoanalysisQueryAPI,
  importGeoanalysisQueryAPI,
} from "@icarius-connection/api";
import { getLocalizedString } from "@icarius-localization/strings";

export const getGeoanalysisGroupsFromServer = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_QUERY_GROUPS });
  try {
    let response = await getGeoanalysisGroupsAPI();
    let groups = response.data && response.data.groups;
    let groupsToFilter = response.data && response.data.groupsToFilter;

    dispatch({
      type: actionTypes.GET_QUERY_GROUPS_FULFILLED,
      payload: {
        groups,
        groupsToFilter,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_QUERY_GROUPS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getGeoanalysisQueriesFromServer = (group) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_QUERIES });
  try {
    let response = await getGeoanalysisQueriesAPI(`?group=${group}`);
    let queries = response.data && response.data.result;

    dispatch({
      type: actionTypes.GET_QUERIES_FULFILLED,
      payload: {
        queries: queries,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_QUERIES_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const executeGeoanalysisQueryFromServer = (query) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_QUERY_RESULT });
  try {
    let response = await executeGeoanalysisQueryAPI(query);
    let dateFormat = response.data && response.data.date_format;
    let currencyLocalization = response.data && response.data.currency_localization;
    let filters = response.data && response.data.filters;
    let result = response.data && response.data.result;
    let selectableColumns = response.data && response.data.selectable_columns;
    let nmark = response.data && response.data.nmark;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_QUERY_RESULT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedString("errorExecutingQuery"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_QUERY_RESULT_FULFILLED,
      payload: {
        dateFormat,
        currencyLocalization,
        filters,
        selectableColumns,
        nmark,
        queryResult: result,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_QUERY_RESULT_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const exportQuery = (query) => async (dispatch) => {
  dispatch({ type: actionTypes.IMPORT_EXPORT });
  try {
    const response = await exportGeoanalysisQueryAPI(query);
    dispatch({ type: actionTypes.IMPORT_EXPORT_FULFILLED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.IMPORT_EXPORT_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const importCustomQuery = (query) => async (dispatch) => {
  dispatch({ type: actionTypes.IMPORT_EXPORT });
  try {
    const response = await importGeoanalysisQueryAPI(query);
    dispatch({ type: actionTypes.IMPORT_EXPORT_FULFILLED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.IMPORT_EXPORT_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};