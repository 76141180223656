import React, { useState } from "react";
import GeoQueryDefinition from "./geoQueryDefinition";
import paths from "@icarius-localization/paths";
import { GeoQueryDefinitionParameters } from "@icarius-pages/geoQueryParameters";

const GeoQueryDefinitionSwitch = ({ history }) => {

  const [parametersIsOpen, setParametersIsOpen] = useState(false);
  const [parameter, setParameter] = useState(null);

  const handleOpenParameterPage = (process) => {
    setParameter(process);
    setParametersIsOpen(true);
  }

  const handleGoBack = () => {
    history.push(paths.managementDefinition);
  }

  return (
    <>
      {
        parametersIsOpen ?
          <GeoQueryDefinitionParameters
            parameter={parameter}
            handleGoBack={() => setParametersIsOpen(false)}
          />
          :
          <GeoQueryDefinition
            handleGoBack={handleGoBack}
            handleOpenParameterPage={handleOpenParameterPage}
          />
      }
    </>
  );
}

export default GeoQueryDefinitionSwitch;
