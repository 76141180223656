import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGoalsAndObjectivesAction,
  getGoalsAndObjectivesAction,
  getGoalsAndObjectivesOneAction,
  verifyGoalsAndObjectivesAction,
} from "../actions";
import {
  getIsLoading,
  getData,
  getCollaboratorList,
  getEnabledCollaboratorActions,
  getPeriodList,
  getStatusList,
  getTaskList,
  getTargetTypeList,
  getTargetList,
  getManager,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { useCallback } from "react";
import { getUserData } from "src/app/selectors";
import { getAnnotationsAction } from "@icarius-pages/annotationsPage/actions";
import { getFeedbackAction, getFeedbackPrivacyOptionsAction } from "@icarius-pages/feedback/actions";
import {
  getIsLoadingPrivacyData,
  getTypeList,
  getModeList,
  getPeople,
  getGroups,
  getTasks,
  getGoals,
  getIsLoadingGridData,
  getIsLoadingFeed,
  getComments,
  getIsLoadingComments,
} from "@icarius-pages/feedback/selectors";
import { formatDateYYYYMMDD, createDateFromDDMMYYYY } from "@icarius-utils/date";
import paths from "@icarius-localization/paths";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const useGoalsAndObjectives = () => {

  const [gridIsOpen, setGridIsOpen] = useState(false);
  const [graphicsIsOpen, setGraphicsIsOpen] = useState(false);
  const [annotationsData, setAnnotationsData] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); //seleccionado para editar o borrar

  const [feedbackFormIsOpen, setFeedbackFormIsOpen] = useState(false);
  const [selectedFeedbackPublication, setSelectedFeedbackPublication] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const collaboratorList = useSelector(getCollaboratorList);
  const enabledCollaboratorActions = useSelector(getEnabledCollaboratorActions);
  const periodList = useSelector(getPeriodList);
  const targetTypeList = useSelector(getTargetTypeList);
  const targetList = useSelector(getTargetList);
  const statusList = useSelector(getStatusList);
  const taskList = useSelector(getTaskList);
  const manager = useSelector(getManager);
  const isLoading = useSelector(getIsLoading);
  const userData = useSelector(getUserData);

  //feedback
  const typeList = useSelector(getTypeList);
  const modeList = useSelector(getModeList);
  const people = useSelector(getPeople);
  const groups = useSelector(getGroups);
  const tasks = useSelector(getTasks);
  const goals = useSelector(getGoals);
  const comments = useSelector(getComments);

  const isLoadingPrivacyData = useSelector(getIsLoadingPrivacyData);
  const isLoadingGridData = useSelector(getIsLoadingGridData);
  const isLoadingFeed = useSelector(getIsLoadingFeed);
  const isLoadingComments = useSelector(getIsLoadingComments);

  const isLoadingFeedback = {
    gridData: isLoadingGridData,
    privacyData: isLoadingPrivacyData,
    feed: isLoadingFeed,
    comments: isLoadingComments,
  }

  const { level } = userData;

  const periodIsClosed = periodList.length ? periodList.find((item) => item.key === selectedPeriod)?.status === 'C' : true;
  const canOperate = !periodIsClosed && ((level === 'C' && enabledCollaboratorActions) || level !== 'C');

  const showVerificationMessage = useCallback((verificationFailures) => {
    if (verificationFailures.length) {
      const number = formatNumberExactDecimals(verificationFailures[0].weighting, 2);

      dispatch(openSnackbarAction({
        msg: `La suma de los ponderados totaliza ${number}% para ${verificationFailures[0].value}. Es necesario complementar con otro objetivo o reasignar los ponderados`,
        severity: 'error',
        duration: 10000,
      }));
      return;
    }

    dispatch(openSnackbarAction({
      msg: level === 'C' ? 'La suma de los ponderados es correcta y totaliza 100% para sus objetivos asignados' : 'La suma de los ponderados es correcta y totaliza 100% para los colaboradores seleccionados',
      severity: 'info',
      duration: 10000,
    }));
    return;
  }, [dispatch, level])

  useEffect(() => {
    dispatch(getGoalsAndObjectivesAction(selectedPeriod))
      .then((resp) => {
        if (resp.status === 200 && selectedPeriod && selectedPeriod !== '-' && Boolean(resp.data.data.length)) {
          showVerificationMessage(resp.data.verificationFailures);
        }
      })
  }, [dispatch, selectedPeriod, showVerificationMessage])

  useEffect(() => { // setear por defecto el que tenga la prop default (si es que no hay ninguno ya seteado)
    if (!selectedPeriod && Boolean(periodList.length)) {
      setSelectedPeriod(periodList.find((item) => item.default)?.key || '');
    }
  }, [periodList, selectedPeriod])

  useEffect(() => {
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenGrid = () => setGridIsOpen(true);
  const handleCloseGrid = () => setGridIsOpen(false);

  const handleDelete = (selectedRow) => {
    if (level === 'C' && selectedRow.assigner !== userData.code) {
      dispatch(openSnackbarAction({
        msg: `El objetivo no puede eliminarse, ha sido creado por su ${selectedRow.role.toLowerCase()}`,
        severity: "warning"
      }));
      return;
    }
  
    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar el objetivo?",
      onConfirm: () => dispatch(deleteGoalsAndObjectivesAction({ internalCode: selectedRow.internalCode })),
    }));
  }

  const handleOpenDeleteDialogByUser = (selectedRow) => {
    handleDelete(selectedRow);
  }

  const handleOpenDeleteDialogByGrid = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    handleDelete(selectedRows[0]);
  }

  const handleOpenCreateDialog = () => {
    setSelectedRow(null);
    setCreateDialogIsOpen(true);
  }

  const handleEdit = (internalCode) => {
    dispatch(getGoalsAndObjectivesOneAction(internalCode))
    .then((resp) => {
      setSelectedRow(resp.data.data);
      if (resp.status === 200) {
        dispatch(getFeedbackAction(false))
          .then((resp) => {
            if (resp.status === 200) {
              dispatch(getFeedbackPrivacyOptionsAction())
                .then((resp) => {
                  if (resp.status === 200) {
                    setEditDialogIsOpen(true);
                  }
                })
            }
          })
      }
    })
  }

  const handleOpenEditDialogByUser = (internalCode) => {
    if (!internalCode) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    handleEdit(internalCode);
  }

  const handleOpenEditDialogByGrid = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    handleEdit(selectedRows[0].internalCode);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedRow(null);
  }

  const handleOpenAnnotations = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(getAnnotationsAction(selectedRows[0].code))
      .then(() => setAnnotationsData({
        employee: {
          "CODIGO DE EMPLEADO": selectedRows[0].code,
          "APELLIDO Y NOMBRE": selectedRows[0].name,
          "MANAGER": selectedRows[0].manager,
        },
        userDates: selectedRows[0].userDates,
      }));
  }

  const handleCloseAnnotations = () => {
    setAnnotationsData(null);
  }

  const verify = (collaborators) => {
    dispatch(verifyGoalsAndObjectivesAction({ collaborators, period: selectedPeriod }))
      .then((resp) => {
        if (resp.status === 200) {
          showVerificationMessage(resp.data.verificationFailures);
        }
      })
  }

  const handleVerifyByGrid = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length < 1 || !selectedPeriod) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro y un período', severity: "warning" }));
      return;
    }

    verify(selectedRows.map((item) => item.code));
  }

  const handleVerifyByUser = (userCodes) => {
    if (!userCodes.length || !selectedPeriod) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro y un período', severity: "warning" }));
      return;
    }

    verify(userCodes);
  }

  const handleChangePeriod = (newPeriod) => setSelectedPeriod(newPeriod);

  const handleOpenGraphics = () => setGraphicsIsOpen(true);
  const handleCloseGraphics = () => setGraphicsIsOpen(false);

  const handleOpenFeedbackForm = (isCreate, formData) => {
    if (!isCreate && (
      (selectedRow.progress && selectedRow.progress !== formData.progress)
      ||
      (
        selectedRow.endDate &&
        formatDateYYYYMMDD(createDateFromDDMMYYYY(selectedRow.endDate)) !== formatDateYYYYMMDD(formData.endDate))
    )) {
      dispatch(openDialogAction({
        title: 'Atención',
        msg: `Ha modificado datos que se incluyen en el Feedback (fecha de término o % de progreso), actualice primero antes de enviar el Feedback.`,
        acceptOnly: true,
      }));
      return;
    }

    setFeedbackFormIsOpen(true);
  }
  const handleCloseFeedbackForm = () => setFeedbackFormIsOpen(false);

  const handleOpenFeedbackPublication = (feedbackPublication) => {
    setSelectedFeedbackPublication(feedbackPublication);
  }

  const handleCloseFeedbackPublication = () => setSelectedFeedbackPublication(null);

  const handleCreatePublication = (dataToSend) => {
    let newSelectedRow = { ...selectedRow };
    selectedRow.feedbacks.unshift(dataToSend.data.data)
    setSelectedRow(newSelectedRow);
  }

  const ownColumnDef = useMemo(() => {
    let columnDefinitions = getColumnDefByPage(selectedPeriod === '-' ? 'goalsAndObjectivesAllPeriods' : paths.goalsAndObjectives);
    const dynamicColumns = [];

    let item1, item2;

    if (level === 'E') {
      item1 = {
        headerName: '¿Aprobado por el colaborador?',
        field: 'approvedByCollaboratorString',
        filter: "agTextColumnFilter",
      };
      item2 = {
        headerName: '¿Aprobado por el manager?',
        field: 'approvedByManagerString',
        filter: "agTextColumnFilter",
      };
    }

    if (level === 'M') {
      item1 = {
        headerName: '¿Aprobado por el colaborador?',
        field: 'approvedByCollaboratorString',
        filter: "agTextColumnFilter",
      };
      item2 = {
        headerName: '¿Debo aprobar el objetivo?',
        field: 'pendingApproval',
        filter: "agTextColumnFilter",
      };
    }

    if (level === 'C') {
      item1 = {
        headerName: '¿Aprobado por el manager?',
        field: 'approvedByManagerString',
        filter: "agTextColumnFilter",
      };
      item2 = {
        headerName: '¿Debo aprobar el objetivo?',
        field: 'pendingApproval',
        filter: "agTextColumnFilter",
      };
    }

    const item3 = { // este va siempre pero al final
      headerName: 'Rol del asignador',
      field: 'role',
      filter: "agSetColumnFilter",
    };

    dynamicColumns.push(item1);
    dynamicColumns.push(item2);
    dynamicColumns.push(item3);

    return [...columnDefinitions, ...dynamicColumns];
  }, [selectedPeriod, level])

  const state = {
    selectedRow,
    selectedPeriod,
    gridIsOpen,
    editDialogIsOpen,
    createDialogIsOpen,
    annotationsData,
    graphicsIsOpen,
    feedbackFormIsOpen,
    selectedFeedbackPublication,
  }

  const handlers = {
    handleVerifyByGrid,
    handleVerifyByUser,
    handleChangePeriod,
    handleOpenGrid,
    handleCloseGrid,
    handleOpenDeleteDialogByGrid,
    handleOpenDeleteDialogByUser,
    handleOpenCreateDialog,
    handleOpenEditDialogByGrid,
    handleOpenEditDialogByUser,
    handleCloseCreateEditDialog,
    handleOpenAnnotations,
    handleCloseAnnotations,
    handleOpenGraphics,
    handleCloseGraphics,
    handleOpenFeedbackForm,
    handleCloseFeedbackForm,
    handleOpenFeedbackPublication,
    handleCloseFeedbackPublication,
    handleCreatePublication,
  }

  return {
    isLoading,
    state,
    handlers,
    canOperate,
    level,
    data,
    taskList,
    manager,
    collaboratorList,
    periodList,
    statusList,
    targetTypeList,
    targetList,
    ownColumnDef,

    //feedback
    typeList,
    modeList,
    people,
    groups,
    tasks,
    goals,
    comments,
    isLoadingFeedback,
  };
}

export default useGoalsAndObjectives;