import * as actionTypes from "./actionTypes";
import {
  getFunctionaryTypesSettingsAPI,
  deleteFunctionaryTypesSettingsAPI,
  createFunctionaryTypesSettingsAPI,
  modifyFunctionaryTypesSettingsAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case"DUPLICATED_DATA":
      errorString = getLocalizedString("functionaryTypeDuplicatedData");
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getFunctionaryTypesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getFunctionaryTypesSettingsAPI();

    let data = response.data && response.data.data;
    let types = response.data && response.data.types;
    let requires = response.data && response.data.requires;
    let status = response.data && response.data.status;    

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, types, requires },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteFunctionaryTypeAction = (permission) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteFunctionaryTypesSettingsAPI(permission);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("functionaryTypeDeletedOK"), severity: "success" }));
    return response;
  } catch (e) {
    if(e.response.data.status === "FUNCTIONARY_TYPE_IN_USE"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("cantDeleteFunctionaryType"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));  
    }
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
  }
};

export const createFunctionaryTypeAction = (permission) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createFunctionaryTypesSettingsAPI(permission);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("functionaryTypeCreatedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyFunctionaryTypeAction = (permission) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyFunctionaryTypesSettingsAPI(permission);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("functionaryTypeModifiedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};