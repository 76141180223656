import {
  GET_GENERAL_SETTINGS_ROWS,
  GET_GENERAL_SETTINGS_ROWS_FULFILLED,
  GET_GENERAL_SETTINGS_ROWS_REJECTED,
} from "./actionTypes";

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_GENERAL_SETTINGS_ROWS:
      return { ...state, loading: true };
    case GET_GENERAL_SETTINGS_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
        generalSettings: action.payload.generalSettings,
      };
    case GET_GENERAL_SETTINGS_ROWS_REJECTED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
