import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  rows: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    //GET
    case actionTypes.GET_FUNCTIONS_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.GET_FUNCTIONS_SETTINGS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
      };
    case actionTypes.GET_FUNCTIONS_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    //UPDATE
    case actionTypes.UPDATE_FUNCTIONS_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_FUNCTIONS_SETTINGS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_FUNCTIONS_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    //CREATE
    case actionTypes.CREATE_FUNCTIONS_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FUNCTIONS_SETTINGS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.CREATE_FUNCTIONS_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    //DELETE
    case actionTypes.DELETE_FUNCTIONS_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FUNCTIONS_SETTINGS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_FUNCTIONS_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
