import React from "react";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, Typography, useMediaQuery } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ProfileImg from "@icarius-common/profileImg";
import { VariableSizeList as List } from 'react-window';
import { LG_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";
import DialogTransition from "@icarius-common/dialogTransition";

const MembersDialog = (props) => {

  const {
    open,
    title,
    participants,
    employees,
    handleClose,
  } = props;

  const matchesMoreOrEqualThanLG = useMediaQuery(`(min-width:${LG_DEVICE_WIDTH_BREAKPOINT}px)`);
  const matchesMoreOrEqualThanMD = useMediaQuery(`(min-width:800px)`);

  const Row = ({ index, style }) => {
    const employee = employees.find(employee => employee.key === participants[index].key);
    let profileImgToUse = "";
    
    if (Boolean(employee)) {
      if (employee["FOTOGRAFIA"]) {
        profileImgToUse = employee["FOTOGRAFIA"];
      } else if (employee["img"]) {
        profileImgToUse = employee["img"];
      }
    }

    return (
      <div style={style}>
        <div style={{ margin: 10 }}>
          <ProfileImg
            image={profileImgToUse}
            size={220}
            isSquare
          />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px 0px" }}>
            <Typography align="center" variant="h6" className="whiteText" style={{ fontWeight: 100, fontSize: 16 }}>
              {participants[index].name}
            </Typography>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      maxWidth={"md"}
      scroll={"paper"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title || getLocalizedString("groupMembersTitle")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingBottom: 30 }}>
        <div style={{ paddingLeft: 10, display: "flex", alignItems: "center", height: 32 }}>
          <Typography variant="h6" className="whiteText" style={{ fontWeight: 300, fontSize: 18 }}>
            {getLocalizedString("groupMembers")}
          </Typography>
          <Typography variant="h6" className="whiteText" style={{ fontWeight: 700, fontSize: 18, marginLeft: 20 }}>
            {participants.length}
          </Typography>
        </div>
        <List
          layout="horizontal"
          className="List"
          height={300}
          itemCount={participants.length}
          itemSize={() => 240}
          width={matchesMoreOrEqualThanLG ? 900 : matchesMoreOrEqualThanMD ? 600 : 250}
        >
          {Row}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default MembersDialog;