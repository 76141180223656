import { useState } from "react";
import * as yup from 'yup';

const useHandleFormDuplicate = (data, paramNumToUse, duplicateCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "refNum") {
        return data.paramNum;
      }

      if (fieldName === "ref") {
        return data.queryCode;
      }

      if (fieldName === "paramNum") {
        return paramNumToUse;
      }

      if (fieldName === "name") {
        return "";
      }

      return data[fieldName];
    }

    const fieldNames = [
      "ref",
      "refNum",
      "name",
      "paramNum",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const codeRegex = /^[A-Z]+[A-Z0-9]*$/;

    const schema = yup.object().shape({
      ref: yup.string().matches(codeRegex).max(20).required(),
      refNum: yup.string().max(3).required(),
      paramNum: yup.string().max(3).required(),      
      name: yup.string().max(254).required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const formDataToSend = { ...formData };

      formDataToSend.refNum = parseInt(formDataToSend.refNum);      
      formDataToSend.paramNum = parseInt(formDataToSend.paramNum);      

      duplicateCallback(formDataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleFormDuplicate;
